
import React from "react";
import { SectionMiCuentaLogin } from "../components/SectionMiCuentaLogin/SectionMiCuentaLogin"
import { SectionMiCuentaAcceso } from "../components/SectionMiCuentaAcceso/SectionMiCuentaAcceso"


class ScrollToTopOnMount extends React.Component 
{
    componentDidMount() 
    {
        window.scrollTo( 0, 0 );
    }

    render() 
    {
        return null;
    }
}
export function MiCuentaLogin () 
{
    return <>
        <ScrollToTopOnMount />
        <SectionMiCuentaLogin/>
    </>
}

export function MiCuentaAcceso () 
{
    return <>
        <ScrollToTopOnMount />
        <SectionMiCuentaAcceso/>
    </>
}