export function SectionTasaIntereses (){
    return(
        <section className="section tasasInteres">
          <div className="container">
            <h2 className="titleDos">Productos:</h2>
            <article className="tasasInteres-table">
              <div className="tasasInteres-row tasasInteres-head">
                <div className="tasasInteres-col headerTasas"><b>PRODUCTOS</b></div>
                <div className="tasasInteres-col headerTasas"><b>PLAZO <br/>(en cuotas quincenales)</b></div>
                <div className="tasasInteres-col headerTasas"><b>TEM</b></div>
                <div className="tasasInteres-col headerTasas"><b>TEA</b></div>
                <div className="tasasInteres-col headerTasas"><b>TCEA <br/> promedio</b></div>
                <div className="tasasInteres-col headerTasas"><b>Comisión</b></div>
                <div className="tasasInteres-col headerTasas"><b>IGV</b></div>
                <div className="tasasInteres-col headerTasas"><b>Penalidad <br/>por mora diaria</b></div>
              </div>
              <div className="tasasInteres-row">
                <div className="tasasInteres-col product">
                  <p><b>0% interés</b></p>
                </div>
                <div className="tasasInteres-col">
                  <p>Hasta 4</p>
                </div>
                <div className="tasasInteres-col">
                <p>0.00%</p>
                </div>
                <div className="tasasInteres-col">
                <p>0.00%</p>
                </div>
                <div className="tasasInteres-col">
                <p>0.00%</p>
                </div>
                <div className="tasasInteres-col">
                  <p>0.00% </p>
                </div>
                <div className="tasasInteres-col">
                  <p>0.00%</p>
                </div>
                <div className="tasasInteres-col">
                  <p>S/ 0.70</p>
                </div>
              </div>
              <div className="tasasInteres-row">
                <div className="tasasInteres-col product finan">
                  <p><b>Financiero 9</b></p>
                </div>
                <div className="tasasInteres-col border">
                  <p> Hasta 3</p>
                </div>
                <div className="tasasInteres-col border">
                  <p>3.95%</p>
                </div>
                <div className="tasasInteres-col border">
                  <p> 59.25%</p>
                </div>
                <div className="tasasInteres-col border">
                  <p>128.75%</p>
                </div>
                <div className="tasasInteres-col border">
                  <p>S/ 4.90</p>
                </div>
                <div className="tasasInteres-col border">
                  <p>18.00% </p>
                </div>
                <div className="tasasInteres-col border">
                  <p>S/ 0.70</p>
                </div>
                <div className="tasasInteres-col">
                  <p> De 4 a 9 </p>
                </div>
                <div className="tasasInteres-col">
                <p>5.18%</p>
                </div>
                <div className="tasasInteres-col">
                <p>83.23%</p>
                </div>
                <div className="tasasInteres-col">
                <p>128.75%</p>
                </div>
                <div className="tasasInteres-col">
                  <p>S/ 4.90</p>
                </div>
                <div className="tasasInteres-col">
                  <p>18.00% </p>
                </div>
                <div className="tasasInteres-col">
                  <p>S/ 0.70</p>
                </div>
              </div>
              
            </article>
          </div>
        </section>
    )
}