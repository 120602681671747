export function SectionTiendasDetails () {
  return (
    <section className="section section-bancos-details">
      <div className="container">
        <aside>
          <img src="img/bancos/aside.png" className="consideraciones-img" alt="" />
        </aside>
        <article>
          <h2 className="title">Comisión</h2>
          <p className="bancos-details__subtitle">Cero, no hay comisión por el pago de tu cuota en nuestros comercios afiliados. </p>
          <h2 className="title">Consideraciones</h2>
          <div className="consideraciones-wraper">
            <div>
              <h3 className="consideraciones-title">
                <img src="img/bancos/limites-pagos.png" alt="" />
                Límites de pago
              </h3>
              <ul className="consideraciones-list">
                <li>
                  <p>Actualmente sólo estamos recibiendos pagos en efectivo.</p>
                </li>
                
              </ul>
            </div>
            <div>
              <h3 className="consideraciones-title">
                <img src="img/bancos/otros.png" alt="" />
                Otros
              </h3>
              <ul className="consideraciones-list">
                <li>
                  <p></p>
                </li>
              </ul>
            </div>
          </div>
        </article>
      </div>
    </section>
  )
}