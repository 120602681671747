export function SectionWebDetails () {
  return (
    <section className="section section-bancos-details">
      <div className="container">
        <aside>
          <img src="img/bancos/aside.png" className="consideraciones-img" />
        </aside>
        <article>
          <h2 className="title">Comisión</h2>
          <p className="bancos-details__subtitle">Pagando en nuestra web, no tienes comisión y podrás pagar tu cuota con cualquier tarjeta de crédito, débito u otras. </p>
          <h2 className="title">Consideraciones</h2>
          <div className="consideraciones-wraper">
            <div>
              <h3 className="consideraciones-title">
                <img src="img/bancos/limites-pagos.png" alt="" />
                Límites de pago
              </h3>
              <ol className="consideraciones-list">
                <li>
                  <p>A partir de la 2da. cuota.</p>
                </li>
                <li>
                  <p>Puedes realizar el pago de tus cuotas totales y/o parciales.</p>
                </li>
              </ol>
            </div>
            <div>
              <h3 className="consideraciones-title">
                <img src="img/bancos/otros.png" alt="" />
                Otros
              </h3>
              <ul className="consideraciones-list">
                <li>
                  <p> </p>
                </li>

              </ul>
            </div>
          </div>
        </article>
      </div>
    </section>
  )
}