export function SectionTiendasPasos () {
  return (
    <section className="section section-pagos-pasos">
      <div className="container">
        <h2 className="title">Pasos para pagar en nuestras tiendas afiliadas</h2>
        <div className="pagos-pasos__wraper">
          <ul className="pagos-pasos__list">
            <li>
              <img src="img/bancos/check.png" alt="" />
              <h4>Acércate a cualquier tienda donde compraste tu equipo.</h4>
            </li>
            <li>
              <img src="img/bancos/check.png" alt="" />
              <h4>Solicita pagar tu crédito Mis Cuotas, asegúrate tener a la mano tu número de DNI.</h4>
            </li>
            <li>
              <img src="img/bancos/check.png" alt="" />
              <h4>Brinda tu número de DNI y tipo de pago que realizarás.</h4>
            </li>
            <li>
              <img src="img/bancos/check.png" alt="" />
              <h4>Realiza el pago, pide tu comprobante y guárdalo.</h4>
            </li>
            <li>
              <img src="img/bancos/check.png" alt="" />
              <h4>Nosotros nos encargamos del resto. Verás tu pago procesado en un máximo de 48 horas.</h4>
            </li>
          </ul>
          <aside>
            <img src="img/web/aside.png" className="pagos-pasos__image" alt="" />
          </aside>
        </div>
      </div>
    </section>
  )
}