import { Respuesta } from "../Respuesta/Respuesta"

export function RespuestasQuinto () {

  return <>
    <Respuesta i={1} title="¿En dónde puedo consultar mi fecha límite de pago?">
      <p>Puedes consultar tu fecha límite de pago ingresando a tu cuenta aquí.</p>
    </Respuesta>
    <Respuesta i={2} title="¿En dónde puedo consultar el monto de mi pago total?">
      <p>Puedes consultar el monto de tu pago total ingresando a tu cuenta aquí.</p>
    </Respuesta>
    <Respuesta i={3} title="¿En dónde consulto mi número de crédito para pagar en Kasnet?">
      <p>Puedes consultar tu número de crédito ingresando a tu cuenta aquí.</p>
    </Respuesta>
  </>
}