import { Respuesta } from "../Respuesta/Respuesta"

export function RespuestasPrimero () {

  return <>
    <Respuesta i={1} title="¿Qué es Mis Cuotas?">
      <p>Es un método de pago para tus compras. Te damos hasta S/ 2,000.00 para comprar en tus comercios favoritos y nos devuelves hasta en 9 cuotas. </p>
      <p>Sin documentación ni papeleo. Sólo te pediremos un par de datos de carácter personal.</p>
      <p><b>
      Tu nos dices el monto que requieres para hacer tu compra y nosotros confirmamos tu crédito en minutos.
      </b></p>
    </Respuesta>
    <Respuesta i={2} title="¿Quién puede comprar con Mis Cuotas?">
      <p>Cualquier persona particular con su Documento Nacional de Identidad (DNI) .</p>
    </Respuesta>
    <Respuesta i={3} title="¿Cuáles son los requisitos para comprar con Mis Cuotas?">
        <ul>
          <li>Ser mayor de edad.</li>
          <li>Tener tu documento nacional de identidad en físico y vigente.</li>
          <li>Contar con un celular propio.</li>
          <li>Una dirección de correo electrónica válida.</li>
          <li>Sin papeleo, sin referencias. No solicitamos certificados de ingreso, pagarés, ni ninguna otra documentación.</li>
        </ul>
    </Respuesta>
    <Respuesta i={4} title="¿Qué documentación necesito para validar mi solicitud de financiación?">
      <p>Mis Cuotas solicitará que completes datos correspondientes a tu documento de identidad (DNI). No requieres otra documentación para tu solicitud del préstamo.</p>
    </Respuesta>
    <Respuesta i={5} title="¿Cómo funciona Mis Cuotas dentro de una tienda?">
      <p>Mis Cuotas funciona como un método de pago disponible dentro de las opciones en el checkout del comercio afiliado. </p>
      <p>Solicitas se realice una evaluación a través de nuestra plataforma y nosotros te brindaremos una respuesta al momento.</p>
    </Respuesta>
    <Respuesta i={6} title="¿Dónde puedo comprar con Mis Cuotas?">
      <p>En cualquier tienda que incluya Mis Cuotas como método de pago. </p>
      <p>Para mayor información revisa nuestro directorio de comercios afiliados y solicita tu evaluación con Mis Cuotas.</p>
    </Respuesta>
    <Respuesta i={7} title="¿Cómo contacto con atención al cliente?">
      <p>Ponte en contacto con nuestro equipo de atención al cliente mediante el correo electrónico:<b> servicioalcliente@miscuotas.com</b> o al número de WhatsApp: <b> +51 967 421 523 </b> , de lunes a viernes de 9am a 6pm.  </p>
    </Respuesta>
  </>
}