import { useState, useEffect } from "react";
import axios from 'axios'

export function SectionLibroReclamaciones() {

/* 
  const [token, setToken] = useState("");

  const [inputnombre, cambiarInputNombre] = useState('')
  const handleInputnombre = (e) => {
    cambiarInputNombre(e.target.value);
  }

  const [inputTypeDoc, cambiarInputTypeDoc] = useState('')
  const handleInputTypeDoc = (e) => {
    cambiarInputTypeDoc(e.target.value);
  }

  const [inputDocumento, cambiarInputDocumento] = useState('')
  const handleInputDocumento = (e) => {
    cambiarInputDocumento(e.target.value);
  }

  const [inputApellPa, cambiarInputApellPa] = useState('')
  const handleInputApellPa = (e) => {
    cambiarInputApellPa(e.target.value);
  }

  const [inputApellMa, cambiarInputApellMa] = useState('')
  const handleInputApellMa = (e) => {
    cambiarInputApellMa(e.target.value);
  }

  const [inputEmail, cambiarInputEmail] = useState('')
  const handleInputEmail = (e) => {
    cambiarInputEmail(e.target.value);
  }

  const [inputTele, cambiarInputTele] = useState('')
  const handleInputTele = (e) => {
    cambiarInputTele(e.target.value);
  }

  const [inputDirec, cambiarInputDirec] = useState('')
  const handleInputDirec = (e) => {
    cambiarInputDirec(e.target.value);
  }

  const [inputNomPaMa, cambiarInputNomPaMa] = useState('')
  const handleInputNomPaMa = (e) => {
    cambiarInputNomPaMa(e.target.value);
  }

  const [inputTypeDocPaoMa, cambiarInputTypeDocPaoMa] = useState('')
  const handleInputTypeDocPaoMa = (e) => {
    cambiarInputTypeDocPaoMa(e.target.value);
  }

  const [inputDocPaoMa, cambiarInputDocPaoMa] = useState('')
  const handleInputDocPaoMa = (e) => {
    cambiarInputDocPaoMa(e.target.value);
  }

  const [inputTienFina, cambiarInputTienFina] = useState('')
  const handleInputTienFina = (e) => {
    cambiarInputTienFina(e.target.value);
  }

  const [inputTipoProd, cambiarInputTipoProd] = useState('')
  const handleInputTipoProd = (e) => {
    cambiarInputTipoProd(e.target.value);
  }
  const [inputTipoAsunto, cambiarInputTipoAsunto] = useState('')
  const handleInputTipoAsunto = (e) => {
    cambiarInputTipoAsunto(e.target.value);
  }
  
  const [inputMonto, cambiarInputMonto] = useState('')
  const handleInputMonto = (e) => {
    cambiarInputMonto(e.target.value);
  }

  const [inputCredito, cambiarInputCredito] = useState('')
  const handleInputCredito = (e) => {
    cambiarInputCredito(e.target.value);
  }

  const [inputBoletaF, cambiarInputBoletaF] = useState('')
  const handleInputBoletaF = (e) => {
    cambiarInputBoletaF(e.target.value);
  }
  

  const [inputDesc, cambiarInputDesc] = useState('')
  const handleInputDesc = (e) => {
    cambiarInputDesc(e.target.value);
  }
  const [empresa, ruc, direccion] = useState('')
  const [inputTypeRecl, cambiarInputTypeRecl] = useState('')
  const handleInputTypeRecl = (e) => {
    cambiarInputTypeRecl(e.target.value);
  }

  const [inputDetaRecla, cambiarInputDetaRecla] = useState('')
  const handleInputDetaRecla = (e) => {
    cambiarInputDetaRecla(e.target.value);
  }

  const [inputTypeRespuesta, cambiarInputRespuesta] = useState('')
  const handleInputTypeRespuesta = (e) => {
    cambiarInputRespuesta(e.target.value);
  }
  

  const [inputPedido, cambiarInputPedido] = useState('')
  const handleInputPedido = (e) => {
    cambiarInputPedido(e.target.value);
  }

  const [inputAdj, cambiarInputAdj] = useState('')
  const [inputName, cambiarInputNameAdju] = useState('')
  const [actualState, changeCheckState] = useState(false);
  const handleInputAdj = e => {

     convertirBase64(e.target.files)
     cambiarInputNameAdju(e.target.files[0].name);

  }
  const [codigoReclamo, cambiarCodigoReclamo] = useState('')

  useEffect(() => {
    const data2 = {
      usuario: "MISCUOTASWeb",
      clave: "12345678",
      tipo: "Web",
      EmpresaId: "10717122938"
    };

    axios.post(`https://miscuotas.com:8901/api`, data2)
      .then(res => {
        setToken(res.data.access_token);
        console.log(res.data.access_token);
      })
  }, [])

  const convertirBase64 = (archivos) => {
    Array.from(archivos).forEach(archivo => {
      var reader = new FileReader();
      reader.readAsDataURL(archivo);
      reader.onload = function () {
        var base64 = reader.result;
        console.log(base64);
        cambiarInputAdj(base64);
      }
    })
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data3 = {
      reclamoId: 0,
      reclamoTipoDocumento: inputTypeDoc,
      reclamoDocumento: inputDocumento,
      reclamoNombre: inputnombre,
      reclamoApePaterno: inputApellPa,
      reclamoApeMaterno: inputApellMa,
      reclamoTipoRespuesta: inputTypeRespuesta,
      reclamoDireccion: inputDirec,
      reclamoTelefono: inputTele,
      reclamoEmail: inputEmail,
      MayorNombre: inputNomPaMa,
      MayorTipoDocumento: inputTypeDocPaoMa,
      MayorDocumento: inputDocPaoMa,
      contratadoTiendaId: inputTienFina,
      contratadoId: inputTipoProd,
      contratadoMonto: inputMonto,
      contratoAsunto:inputTipoAsunto,
      contratoNroCredito:inputCredito,
      contratoNroBolletaF: inputBoletaF,
      detalleTipoReclamo: inputTypeRecl,
      detalleReclamo: inputDetaRecla,
      detallePedido: inputPedido,
      reclamoCanal: "WEB",
      PlantillaId: "27",
      ParametrosCodigo: "SERCLI",
      ParametrosStDring: "",
      Asunto: "Reclamo MisCuotas Caso Nro: ",
    }
    const headers1 = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }


    axios.post(`https://miscuotas.com:8903/api2`, data3, { headers: headers1 })
      .then(res => {
        cambiarCodigoReclamo(res.data.mensaje);
        changeCheckState(true);
      })

    try {
      const respuesta = await axios.post('http://localhost:8080/v2/reclamaciones', data3, { headers: headers1 });

      if (respuesta.status === 200) {
        console.log('Correo enviado con éxito');
      } else {
        console.error('Error al enviar el correo:', respuesta);
      }
    } catch (error) {
      console.error('Error al enviar el correo:', error);
    }
  }

  return <>

    <section className="section sectionReclamo">
      <article className="container">
        <h2 className="title">Libro de Reclamaciones</h2>
        <p style={{ textAlign: 'justify', fontSize: '1.5rem', fontWeight: 'normal' }}>Las reclamaciones y quejas recibidas en este libro se atenderán en relación a las ventas realizadas bajo la razón social "Te Presto Servicios Financieros SAC".</p>
        <p style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}>QTC SERVICIOS FINANCIEROS S.A.C.</p>
        <p style={{ textAlign: 'center', marginBottom: '2.5rem', fontSize: '1.5rem', fontWeight: 'bold' }}>RUC: 20608863762</p>
        <p style={{ textAlign: 'center', marginBottom: '2.5rem', fontSize: '1.5rem', fontWeight: 'bold' }}>Dirección Fiscal: Cal. Chiclayo Nro. 562, Miraflores, Lima</p>
        <form className="reclamacionesForm" onSubmit={handleSubmit}>
          <h3 className="reclamacionesForm__title">1.- Identificación del consumidor reclamante</h3>
          <div className="reclamacionesForm__row">
            <select name="" id="" value={inputTypeDoc} onChange={handleInputTypeDoc} required>
              <option value="00" defaultValue hidden>Tipo de documento</option>
              <option value="DNI">DNI</option>
              <option value="PASAPORTE">Pasaporte</option>
            </select>
            <input type="text" placeholder="Numero de documento" value={inputDocumento} onChange={handleInputDocumento} required />
          </div>
          <div className="reclamacionesForm__row">
            <input type="text" placeholder="Nombres" className="reclamacionesForm__input--span-2" value={inputnombre} onChange={handleInputnombre} required />
          </div>
          <div className="reclamacionesForm__row">
            <input type="text" placeholder="Apellido paterno" value={inputApellPa} onChange={handleInputApellPa} required />
            <input type="text" placeholder="Apellido materno" value={inputApellMa} onChange={handleInputApellMa} required />
          </div>
          <div className="reclamacionesForm__row">
            <select name="" id="" value={inputTypeRespuesta} onChange={handleInputTypeRespuesta} required>
              <option value="00" defaultValue hidden>Respuesta al reclamo</option>
              <option value="Fisico">Recibir Físico</option>
              <option value="Email">Recibir por Correo</option>
            </select>
          </div>
          <div className="reclamacionesForm__row">
            <input type="emil" placeholder="Email" value={inputEmail} onChange={handleInputEmail} required />
            <input type="tel" placeholder="Telefono" value={inputTele} onChange={handleInputTele} required />
          </div>
          <div className="reclamacionesForm__row">
            <input type="text" placeholder="Dirección" className="reclamacionesForm__input--span-2" value={inputDirec} onChange={handleInputDirec} required />
          </div>
          <h3 className="reclamacionesForm__title">Para el caso de menores de edad</h3>
          <div className="reclamacionesForm__row">
            <input type="text" placeholder="Nombre de padre o madre" className="reclamacionesForm__input--span-2" value={inputNomPaMa} onChange={handleInputNomPaMa} />
          </div>
          <div className="reclamacionesForm__row">
            <select name="" id="" value={inputTypeDocPaoMa} onChange={handleInputTypeDocPaoMa}>
              <option value="00" defaultValue hidden>Tipo de documento</option>
              <option value="DNI">DNI</option>
              <option value="PASAPORTE">Pasaporte</option>
            </select>
            <input type="text" placeholder="Número de documento" value={inputDocPaoMa} onChange={handleInputDocPaoMa} />
          </div>
          <h3 className="reclamacionesForm__title">2.- Información general</h3>
          <div className="reclamacionesForm__row">
            <select value={inputTienFina} onChange={handleInputTienFina} required>
              <option value="" defaultValue disabled hidden>Tienda de financiamiento *</option>
              <option value="AREQUIPA XIAOMI">AREQUIPA XIAOMI</option>
              <option value="BELLAVISTA XIAOMI">BELLAVISTA XIAOMI</option>
              <option value="CENTRO CIVICO XIAOMI">CENTRO CIVICO XIAOMI</option>
              <option value="CHICLAYO XIAOMI">CHICLAYO XIAOMI</option>
              <option value="COMAS XIAOMI">COMAS XIAOMI</option>
              <option value="CUSCO XIAOMI">CUSCO XIAOMI</option>
              <option value="JIRON DE LA UNION XIAOMI">JIRON DE LA UNION XIAOMI</option>
              <option value="JOCKEY PLAZA XIAOMI">JOCKEY PLAZA XIAOMI</option>
              <option value="MALL DEL SUR XIAOMI">MALL DEL SUR XIAOMI</option>
              <option value="MEGA PLAZA XIAOMI">MEGA PLAZA XIAOMI</option>
              <option value="MIRAFLORES XIAOMI">MIRAFLORES XIAOMI</option>
              <option value="MOD. SALAVERRY XIAOMI">MOD. SALAVERRY XIAOMI</option>
              <option value="MOD. SALAVERRY XIAOMI">MOD. SALAVERRY XIAOMI</option>
              <option value="PLAZA NORTE XIAOMI">PLAZA NORTE XIAOMI</option>
              <option value="PURUCHUCO XIAOMI">PURUCHUCO XIAOMI</option>
              <option value="SAN MIGUEL XIAOMI">SAN MIGUEL XIAOMI</option>
              <option value="SANTA ANITA XIAOMI">SANTA ANITA XIAOMI</option>
              <option value="HUANCAYO XIAOMI">HUANCAYO XIAOMI</option>
              <option value="MOD. TRUJILLO XIAOMI">MOD. TRUJILLO XIAOMI</option>
              <option value="MOD. MALL DEL SUR XIAOMI">MOD. MALL DEL SUR XIAOMI</option>
              <option value="IQUITOS XIAOMI">IQUITOS XIAOMI</option>
              <option value="COMAS TEC STORE">COMAS TEC STORE</option>
              <option value="JIRON DE LA UNION TEC STORE">JIRON DE LA UNION TEC STORE</option>
              <option value="PRO TEC STORE">PRO TEC STORE</option>
              <option value="SANTA ANITA TEC STORE">SANTA ANITA TEC STORE</option>
              <option value="TRUJILLO TEC STORE">TRUJILLO TEC STORE</option>
              <option value="AREQUIPA TEC STORE">AREQUIPA TEC STORE</option>
              <option value="ICA TEC STORE">ICA TEC STORE</option>
              <option value="CHICLAYO TEC STORE">CHICLAYO TEC STORE</option>
              <option value="CUSCO TEC STORE">CUSCO TEC STORE</option>
              <option value="PLAZA NORTE  TEC STORE">PLAZA NORTE  TEC STORE</option>
              <option value="SAN MIGUEL TEC STORE">SAN MIGUEL TEC STORE</option>
              <option value="IQUITOS TEC STORE">IQUITOS TEC STORE</option>
              <option value="MEGA PLAZA TEC STORE">MEGA PLAZA TEC STORE</option>
              <option value="MALL DEL SUR HONOR">MALL DEL SUR HONOR</option>
              <option value="MEGA PLAZA HONOR">MEGA PLAZA HONOR</option>
              <option value="SANTA ANITA HONOR">SANTA ANITA HONOR</option>
              <option value="TRUJILLO HONOR">TRUJILLO HONOR</option>
              <option value="MOD. AREQUIPA HONOR">MOD. AREQUIPA HONOR</option>
              <option value="PLAZA NORTE  HONOR">PLAZA NORTE  HONOR</option>
              <option value="MINKA HONOR">MINKA HONOR</option>
              <option value="PURUCHUCO HONOR">PURUCHUCO HONOR</option>
              <option value="SAN MIGUEL DJI">SAN MIGUEL DJI</option>

            </select>
            <select value={inputTipoProd} onChange={handleInputTipoProd} required>
              <option value="" disabled="" hidden="" defaultValue>Tipo de bien contratado *</option>
              <option value="Servicio">Servicio</option>
            </select>
          </div>
          <div className="reclamacionesForm__row">
            <input type="text" placeholder="Monto de reclamo" value={inputMonto} onChange={handleInputMonto} required />
            <select value={inputTipoAsunto} onChange={handleInputTipoAsunto} required>
              <option value="" disabled="" hidden="" defaultValue>Asunto *            </option>
              <option value="Atencion">Atención</option>
              <option value="Bloqueo">Bloqueo</option>
              <option value="Pagos">Pagos</option>
              <option value="Otros">Otros</option>
            </select>
          </div>
          <div className="reclamacionesForm__row">
            <input type="text" placeholder="Número de Crédito" value={inputCredito} onChange={handleInputCredito} required />
            <input type="text" placeholder="Número de Boleta o Factura" value={inputBoletaF} onChange={handleInputBoletaF} required />
          </div>
                   
          <h3 className="reclamacionesForm__title">3.- Detalle de la Reclamación y pedido del consumidor</h3>
          <div className="reclamacionesForm__row">
            <select value={inputTypeRecl} onChange={handleInputTypeRecl} required>
              <option value="" disabled="" hidden="" defaultValue>Tipo de reclamo *</option>
              <option value="Reclamo">Reclamo</option>
              <option value="Queja">Queja</option>
            </select>
          </div>
          <div className="reclamacionesForm__row">
            <textarea className="reclamacionesForm__input--span-2" name="" id="" cols="30" rows="6" placeholder="Detalles del reclamo" value={inputDetaRecla} onChange={handleInputDetaRecla} required></textarea>
          </div>
          <div className="reclamacionesForm__row">
            <textarea className="reclamacionesForm__input--span-2" name="" id="" cols="30" rows="6" placeholder="Pedido" value={inputPedido} onChange={handleInputPedido} required></textarea>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <input type="checkbox" name="loffield_c351[]" id="loffield_c351654d0c8be140f0" value="*La formulación del reclamo no impide acudir a otras vías de solución de controversias ni es requisito previo para interponer una denuncia ante el INDECOPI." className="validate-one-required-by-name required-entry"  defaultChecked={true}/>
              <label htmlFor="loffield_c351654d0c8be140f0">*La formulación del reclamo no impide acudir a otras vías de solución de controversias ni es requisito previo para interponer una denuncia ante el INDECOPI.</label>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
                <input type="checkbox" name="loffield_c351[]" id="loffield_c351654d0c8be140f1" value="* El proveedor debe dar respuesta al reclamo o queja en un plazo no mayor a quince (15) días hábiles, el cual es improrrogable." className="validate-one-required-by-name required-entry"  defaultChecked={true}/>
                <label htmlFor="loffield_c351654d0c8be140f1">* El proveedor debe dar respuesta al reclamo o queja en un plazo no mayor a quince (15) días hábiles, el cual es improrrogable.</label>
            </div>
          </div>
          <button type="submit" className="reclamacionesForm__button">Enviar</button>
        </form>
      </article>
      <input type="checkbox" id="btn-modal" checked={actualState} />
      <div className="container-modal">
        <div className="content-modal">
          <img height="40" src="img/MIS-CUOTAS_fondo blanco.png" className="custom-logo" alt="" />
          <h4>QTC SERVICIOS FINANCIEROS S.A.C.</h4>
          <span>RUC: 20608863762</span>
          <p>Estimado Cliente su Reclamo/Pedido fue registrado correctamente. Brindamos el código del reclamo para su seguimiento <b>{codigoReclamo}</b>. Así mismo le hemos enviado a su correo el detalle del mismo. Gracias por su tiempo.</p>
          <div className="btn-cerrar">
            <label htmlFor="btn-modal" onClick={() => window.location.reload(false)}>cerrar</label>
          </div>
        </div>
        <label htmlFor="btn-modal" className="cerrar-modal"></label>
      </div>
    </section>
  </>
   */

  return <>

    <section className="section sectionReclamo">
      <article className="container">
        <h2 className="title">Optimizando tu experiencia: <br></br>
          Libro de Reclamaciones Temporalmente en Mantenimiento
        </h2>
        <p><i class="fas fa-cog"></i> ¡Estamos mejorando para ti!</p>
        <p>Nuestro libro de Reclamaciones está en mantenimiento. Mientras tanto, puedes registrar tu reclamo en los siguientes enlaces de nuestros comercios afiliados:</p>
        
        <ul className="reclamo">
          <li>
            <strong>Xiaomi:</strong>
            <p>
              <a href="https://xiaomiperu.com/libro-de-reclamaciones" target="_blank">https://xiaomiperu.com/libro-de-reclamaciones</a>
            </p>
          </li>
          <li>
            <strong>Tec Store:</strong>
            <p>
              <a href="https://tecstore.pe/libro-de-reclamaciones" target="_blank">https://tecstore.pe/libro-de-reclamaciones</a>
            </p>
          </li>
          <li>
            <strong>Honor:</strong>
            <p>
              <a href="https://honorperu.pe/libro-de-reclamaciones" target="_blank">https://honorperu.pe/libro-de-reclamaciones</a>
            </p>
          </li>
        </ul>

      <p>Agradecemos tu comprensión y paciencia.. Muy pronto tendremos todo listo para ti.</p>

      </article>
      
      
    </section>
  </>
}