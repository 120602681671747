import { SliderHome } from "../components/SliderHome/SliderHome" 
import { SectionBeneficios } from "../components/SectionBeneficios/SectionBeneficios" 
import { SectionPasos } from "../components/SectionPasos/SectionPasos" 
import { SectionTiendas } from "../components/SectionTiendas/SectionTiendas" 
import React from "react";
class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);

  }

  render() {
    return null;
  }
}
export function Home () {

  return <main>
    <ScrollToTopOnMount />
    <SliderHome />
    <SectionBeneficios />
    <SectionPasos imgPaso1={'img/pasos/img-tab-11.png'} imgPaso2={'img/pasos/img-tab-22.png'} imgPaso3={'img/pasos/img-tab-33.png'} />
    <SectionTiendas />
  </main>
}