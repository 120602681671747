import ReactOwlCarousel from "react-owl-carousel"

export function SectionBeneficiosParaComercios () {
  const options = {
    stagePadding: 1,
    loop:true,
    margin:15,
    nav:false,
    navText: ["<i class=\"fa fa-angle-left\" aria-hidden=\"true\"></i>","<i class=\"fa fa-angle-right\" aria-hidden=\"true\"></i>"],
    dots: true,
    autoplay:true,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    responsive:{
      0:{
        items:1
      },
      600:{
        items:2
      },
      1200:{
        items:4
      }
    }
  
  }
  return (
    <section className="section section-beneficios">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="title">Beneficios que brindamos a nuestro aliados</h2>
              <p>Mira todos los beneficios y más que tenemos para tí por afiliarte a “Mis Compras”</p>
            </div>
          </div>
          <div id="beneficiosCarusel-cards">
            <ReactOwlCarousel {...options} className="owl-carousel owl-theme">
              <div className="beneficios-card beneficios-card-1">
                <img src="img/para_comercios/beneficio-1.png" alt="" />
                <h3>Incrementamos tus <br /> ventas</h3>
              </div>
              <div className="beneficios-card beneficios-card-2">
                <img src="img/para_comercios/beneficio-2.png" alt="" />
                <h3>Marketing <br /> dirigido</h3>
              </div>
              <div className="beneficios-card beneficios-card-3">
                <img src="img/para_comercios/beneficio-3.png" alt="" />
                <h3>Ningún <br /> riesgo</h3>
              </div>
              <div className="beneficios-card beneficios-card-4">
                <img src="img/para_comercios/beneficio-4.png" alt="" />
                <h3>Acceso a miles de <br /> usuarios</h3>
              </div>
            </ReactOwlCarousel>
          </div>
        </div>
      </section>
  )
}