import { useEffect } from "react"

export function SectionMiCuentaLogin()
{
    useEffect
    ( 
        () => 
        {
            let elementHeader = document.querySelector( "header .col-md-10.menu" ); 
            if( elementHeader )
            {
                elementHeader.remove();
            }
            elementHeader = document.querySelector( "header .nav-responsive" ); 
            if( elementHeader )
            {
                elementHeader.remove();
            }

            const timer = setTimeout( () => 
            {
                const url = "https://msextranet.web.app/auth/login";
            
                window.location.replace(url);
                
            }, 3000 );
            return () => clearTimeout( timer );
        }, 
        []
    );

    const estilo = 
    {
        color: 'blue',
        fontSize: '16px',
        marginRight: 0, 
        marginLeft: 0, 
        minHeight: '58vh',
        marginBottom: '0px'
    };
    const estilox = 
    {
        textAlign: 'center'
    };
    const estilodos=
    {
        paddingTop: '120px',
        paddingBottom: '60px',
        background: 'linear-gradient(140.7deg, #F1AE00 0.77%, #FFCC03 52.23%)',
        marginBottom: '200px'
    }
    return(
<div>
    <section className="hero-pagos" >
        <div className="container">
            <article>
                <h2 className="hero-faqs__title">Conoce nuestros</h2>
                <p className="hero-faqs__subtitle">nuevos métodos de pago</p>
                <img src="img/pagos/BANNER-3_yape.png" alt="" id="hero-yape" />
                <img src="img/pagos/BANNER-3_tarjetas.png" alt="" id="hero-tarjeta" />
            </article>
            <img src="img/pagos/BANNER-3_persona.png" id="hero-persona" alt="" />
        </div>
    </section>
    <section className="section section-pagoshero"  style={estilo}>
    <p className="hero-faqs__subtitle" style={estilox}>Ingresarás a un ambiente seguro para realizar tus pagos.</p>
        <div className="content">
            
            <div className="spinner"></div>
        </div>
    </section>
</div>
    )
}