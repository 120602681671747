
import React from "react";
import { SectionLibroReclamaciones } from "../components/SectionLibroReclamaciones/SectionLibroReclamaciones";

class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}
export function LibroReclamaciones () {
  return <>
  <ScrollToTopOnMount />
  <SectionLibroReclamaciones/>
</>
}