import { HeroAyuda } from "../components/HeroAyuda/HeroAyuda"
import { SectionCanales } from "../components/SectionCanales/SectionCanales"
import { SectionConsultas } from "../components/SectionConsultas/SectionConsultas"
import { SectionFaqs } from "../components/SectionFaqs/SectionFaqs"
import React from "react";
class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}
export function Ayuda () {
  return <>
  <ScrollToTopOnMount />
    <HeroAyuda />
    <main>
      <SectionCanales />
      <SectionFaqs />
      <SectionConsultas />
    </main>
  </>
}