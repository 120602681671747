import ReactOwlCarousel from "react-owl-carousel"
import { Link } from "wouter";

export function SectionTiendas() {

  const options = {
    stagePadding: 1,
			loop:true,
			margin:15,
			nav:false,
			navText: ["<i class=\"fa fa-angle-left\" aria-hidden=\"true\"></i>","<i class=\"fa fa-angle-right\" aria-hidden=\"true\"></i>"],
			dots: true,
			autoplay:true,
			autoplayTimeout:3000,
			autoplayHoverPause:true,
			responsive:{
				0:{
					items:1
				},
				600:{
					items:2
				},
				1200:{
					items:5
				}
			}
  };

  return <>
    <section className="section section-tiendas">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <img src="img/beneficios/tiendita.png" alt=""/><br />
            <p className="title">
              Conoce a nuestras Tiendas aliadas
            </p>
            <p>Encuentranos en los establecimientos:</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ReactOwlCarousel {...options} className="owl-carousel owl-theme">
            <a href="https://www.honorperu.pe/nuestras-tiendas" target="_blank" rel="noreferrer">
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas_aliadas/HONOR-MALLDESUR.jpg" alt=""/>
                <div className="logoFlotante">
                  {/* <img src="img/tiendas_aliadas/huaweiLogo.png" /> */}
                </div>
              </div>
              </a>
              <a href="https://consumer.huawei.com/pe/retail/" target="_blank" rel="noreferrer">
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas_aliadas/HUAWEI-AREQUIPA.jpg" alt="" />
                <div className="logoFlotante">
                  {/* <img src="img/tiendas_aliadas/appleLogo.png" /> */}
                </div>
              </div>
              </a>
              <a href="https://xiaomiperu.com/nuestras-tiendas" target="_blank" rel="noreferrer">
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas_aliadas/MISTORE-BELLAVISTA.jpg" alt=""/>
                <div className="logoFlotante">
                  {/* <img src="img/tiendas_aliadas/logoLg.png" /> */}
                </div>
              </div>
              </a>
              <a href="https://tecstore.pe/nuestras-tiendas" target="_blank" rel="noreferrer">
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas_aliadas/TEC-BELLAVISTA.jpg"  alt=""/>
                <div className="logoFlotante">
                  {/* <img src="img/tiendas_aliadas/sansungLogo.png" /> */}
                </div>
              </div>
              </a>
              <a href="https://dji.pe/" target="_blank" rel="noreferrer">
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas_aliadas/DJI.jpg" alt=""/>
                <div className="logoFlotante">
                  {/* <!--  <img src="img/tiendas_aliadas/logoLg.png" /> --> */}
                </div>
              </div>
              </a>
              <a  target="_blank" rel="noreferrer">
              <div className="item-beneficio">
                <img className="icon" src="img/tiendas_aliadas/ZTE.jpg" alt=""/>
              </div>
              </a>
            </ReactOwlCarousel>
          </div>
          <div className="col-md-12">
            <Link to="/dondeComprar" className="btn-primary" >Ver Lista de Tiendas</Link>
          </div>
        </div>
      </div>
    </section>
  </>
}