
import React from "react";
import { SectionTratamientoDeDatos } from "../components/SectionTratamientoDeDatos/SectionTratamientoDeDatos";


class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}
export function TratamientoDeDatos () {
  return <>
  <ScrollToTopOnMount />
  <section className="section section-terminoHero">
        <div className="container">
            <h2 className="title">Tratamiento de Datos</h2>
        </div>
    </section>
    <SectionTratamientoDeDatos></SectionTratamientoDeDatos>
</>
}