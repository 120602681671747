import { useState } from "react"
import { RespuestasBancos } from "../RespuestasBancos/RespuestasBancos"
import { RespuestasPrimero } from "../RespuestasPrimero/RespuestasPrimero"
import { RespuestasSegundo } from "../RespuestasSegundo/RespuestasSegundo" 
import { RespuestasTercero } from "../RespuestasTercero/RespuestasTercero"
import { RespuestasCuarto } from "../RespuestasCuarto/RespuestasCuarto"
import { RespuestasQuinto } from "../RespuestasQuinto/RespuestasQuinto"
export function SectionFaqs () {
  const FAQS = {
    primero: 'Sobre Mis Cuotas',
    segundo: 'Sobre el financiamiento – Sobre el préstamo',
    tercero: 'Sobre los pagos',
    cuarto: 'Sobre mi cuenta',
    quinto: 'Sobre mi préstamo'
  }

  const [query, setQuery] = useState(FAQS.primero)

  const handleQuery = (query) => {
    setQuery(query)
  }

  const handleChange = (e) => {
    setQuery(e.target.value)
  }

  return (
    <section className="section section-faqs">
      <div className="container">
        <h2 className="title">Preguntas Frecuentes</h2>
        <article className="faqs-wraper">
          <div className="faqs-buttons">
            <select name="" id="" className="active" value={query} onChange={handleChange}>
              <option value={FAQS.primero} selected >Sobre Mis Cuotas</option>
              <option value={FAQS.segundo} >Sobre el financiamiento – Sobre el préstamo</option>
              <option value={FAQS.tercero} >Sobre los pagos</option>
              <option value={FAQS.cuarto} >Sobre mi cuenta</option>
              <option value={FAQS.quinto} >Sobre mi préstamo</option>
            </select>
            <button className={query === FAQS.primero ? 'active' : ''} onClick={ () => handleQuery(FAQS.primero)}>Sobre Mis Cuotas</button>
            <button className={query === FAQS.segundo ? 'active' : ''} onClick={ () => handleQuery(FAQS.segundo)}>Sobre el financiamiento – Sobre el préstamo</button>
            <button className={query === FAQS.tercero ? 'active' : ''} onClick={ () => handleQuery(FAQS.tercero)}>Sobre los pagos</button>
            <button className={query === FAQS.cuarto ? 'active' : ''} onClick={ () => handleQuery(FAQS.cuarto)}>Sobre mi cuenta</button>
            <button className={query === FAQS.quinto ? 'active' : ''} onClick={ () => handleQuery(FAQS.quinto)}>Sobre mi préstamo</button>
          </div>
          <div className="faqs-content">
            { query === FAQS.primero && <RespuestasPrimero /> }
            { query === FAQS.segundo && <RespuestasSegundo /> }
            { query === FAQS.tercero && <RespuestasTercero /> }
            { query === FAQS.cuarto && <RespuestasCuarto /> }
            { query === FAQS.quinto && <RespuestasQuinto /> }
          </div>
        </article>
      </div>
    </section>
  )
}