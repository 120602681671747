export function SectionBancosDetails () {
  return (
    <section className="section section-bancos-details">
      <div className="container">
        <aside>
          <img src="img/bancos/aside.png" className="consideraciones-img" alt="" />
        </aside>
        <article>
          <h2 className="title">Comisión</h2>
          <p className="bancos-details__subtitle">Cero, en el caso de que realices Transferencias Bancarias desde el APP o página web (Banca por Internet) de tu banco preferido. </p>
          <h2 className="title">Consideraciones</h2>
          <div className="consideraciones-wraper">
            <div>
              <h3 className="consideraciones-title">
                <img src="img/bancos/limites-pagos.png" alt="" />
                Límites de pago
              </h3>
              <ul className="consideraciones-list">
                <li>
                  <p>Se reciben pagos solo en efectivo y a partir de la 2da. cuota.</p>
                </li>
                
              </ul>
            </div>
            <div>
              <h3 className="consideraciones-title">
                <img src="img/bancos/otros.png" alt="" />
                Otros
              </h3>
              <ul className="consideraciones-list">
                <li>
                  <p>Únicamente aplica para tarjetas titulares.</p>
                </li>
              </ul>
            </div>
          </div>
        </article>
      </div>
    </section>
  )
}