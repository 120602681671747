import { useEffect } from "react";
const $ = window.$;
export function SliderHome() {
  const options = {
    autoplay	:true,
    interval	:10000,
    animations 	: {
      0	: 	//Slide No0
      {
        "#bg-1-1":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay0-5s"
        },
        "#bg-1-2":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay1s"
        },
        "#bg-1-3-1":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay1-5s"
        },
        "#bg-1-4":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay2s"
        },
        "#bg-1-5-1":
        {
          show 	 	: "bounceIn",
          hide 	 	: "bounceOut",
          delayShow   : "delay2-5s"
        },
        "#bg-1-6-1":
        {
          show 	 	: "bounceIn",
          hide 	 	: "bounceOut",
          delayShow   : "delay3s"
        },
        "#bg-1-7-1":
        {
          show 	 	: "bounceIn",
          hide 	 	: "bounceOut",
          delayShow   : "delay3-5s"
        },
        "#bg-1-8":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay4s"
        },
        "#bg-1-9-0":
        {
          show 		: "bounceIn",
          hide 		: "bounceOut",
          delayShow   : "delay2s"
        },
        "#bg-1-text1":
        {
          show 		: "fadeInUp",
          hide 		: "fadeOutUp",
          delayShow   : "delay1s"
        },
        "#bg-1-text2":
        {
          show 		: "fadeInUp",
          hide 		: "fadeOutUp",
          delayShow   : "delay1-5s"
        },
        "#bg-1-p":
        {
          show 		: "fadeInUp",
          hide 		: "fadeOutUp",
          delayShow   : "delay2s"
        },
        "#bg-1-btn-0":
        {
          show 		: "fadeInUp",
          hide 		: "fadeOutUp",
          delayShow   : "delay2-5s"
        }		
      },
      1	: 	//Slide No1
      {
        "#bg-1-1":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay0-5s"
        },
        "#bg-1-2":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay1s"
        },
        "#bg-1-3":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay1-5s"
        },
        "#bg-1-4":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay2s"
        },
        "#bg-1-5":
        {
          show 	 	: "bounceIn",
          hide 	 	: "bounceOut",
          delayShow   : "delay2-5s"
        },
        "#bg-1-6":
        {
          show 	 	: "bounceIn",
          hide 	 	: "bounceOut",
          delayShow   : "delay3s"
        },
        "#bg-1-7":
        {
          show 	 	: "bounceIn",
          hide 	 	: "bounceOut",
          delayShow   : "delay3-5s"
        },
        "#bg-1-8":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay4s"
        },
        "#bg-1-9-1":
        {
          show 		: "bounceIn",
          hide 		: "bounceOut",
          delayShow   : "delay2s"
        },
        "#bg-1-text1":
        {
          show 		: "fadeInUp",
          hide 		: "fadeOutUp",
          delayShow   : "delay1s"
        },
        "#bg-1-text2":
        {
          show 		: "fadeInUp",
          hide 		: "fadeOutUp",
          delayShow   : "delay1-5s"
        },
        "#bg-1-p":
        {
          show 		: "fadeInUp",
          hide 		: "fadeOutUp",
          delayShow   : "delay2s"
        },
        "#bg-1-btn":
        {
          show 		: "fadeInUp",
          hide 		: "fadeOutUp",
          delayShow   : "delay2-5s"
        }		
      },
      2	: //Slide No2
      {	
        "#bg-1-1":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay0-5s"
        },
        "#bg-1-2":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay1s"
        },
        "#bg-1-3":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay1-5s"
        },
        "#bg-1-4":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay2s"
        },
        "#bg-1-5":
        {
          show 	 	: "bounceIn",
          hide 	 	: "bounceOut",
          delayShow   : "delay2-5s"
        },
        "#bg-1-6":
        {
          show 	 	: "bounceIn",
          hide 	 	: "bounceOut",
          delayShow   : "delay3s"
        },
        "#bg-1-7":
        {
          show 	 	: "bounceIn",
          hide 	 	: "bounceOut",
          delayShow   : "delay3-5s"
        },
        "#bg-1-8":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay4s"
        },
        "#bg-1-9-2":
        {
          show 		: "bounceIn",
          hide 		: "bounceOut",
          delayShow   : "delay2s"
        },
        "#bg-1-text1":
        {
          show 		: "fadeInUp",
          hide 		: "fadeOutUp",
          delayShow   : "delay1s"
        },
        "#bg-1-text2":
        {
          show 		: "fadeInUp",
          hide 		: "fadeOutUp",
          delayShow   : "delay1-5s"
        },
        "#bg-1-p":
        {
          show 		: "fadeInUp",
          hide 		: "fadeOutUp",
          delayShow   : "delay2s"
        },
        "#bg-1-btn":
        {
          show 		: "fadeInUp",
          hide 		: "fadeOutUp",
          delayShow   : "delay2-5s"
        }		
      },
      3	: //Slide No3
      {	
        "#bg-1-1":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay0-5s"
        },
        "#bg-1-2":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay1s"
        },
        "#bg-1-3":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay1-5s"
        },
        "#bg-1-4":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay2s"
        },
        "#bg-1-5":
        {
          show 	 	: "bounceIn",
          hide 	 	: "bounceOut",
          delayShow   : "delay2-5s"
        },
        "#bg-1-6":
        {
          show 	 	: "bounceIn",
          hide 	 	: "bounceOut",
          delayShow   : "delay3s"
        },
        "#bg-1-7":
        {
          show 	 	: "bounceIn",
          hide 	 	: "bounceOut",
          delayShow   : "delay3-5s"
        },
        "#bg-1-8":
        {
          show 		: "fadeIn",
          hide 		: "fadeOut",
          delayShow   : "delay4s"
        },
        "#bg-1-9-3":
        {
          show 		: "bounceIn",
          hide 		: "bounceOut",
          delayShow   : "delay2s"
        },
        "#bg-1-text1":
        {
          show 		: "fadeInUp",
          hide 		: "fadeOutUp",
          delayShow   : "delay1s"
        },
        "#bg-1-text2":
        {
          show 		: "fadeInUp",
          hide 		: "fadeOutUp",
          delayShow   : "delay1-5s"
        },
        "#bg-1-p":
        {
          show 		: "fadeInUp",
          hide 		: "fadeOutUp",
          delayShow   : "delay2s"
        },
        "#bg-2-p":
        {
          show 		: "fadeInUp",
          hide 		: "fadeOutUp",
          delayShow   : "delay2s"
        },
        "#bg-1-btn-1":
        {
          show 		: "fadeInUp",
          hide 		: "fadeOutUp",
          delayShow   : "delay2-5s"
        }		
      }
    }
  }

  useEffect( () => {
    $(".anim-slider").animateSlider(options)
  })

  return (
    <section className="banner-desktop">
      <ul className="anim-slider">
        <li className="anim-slide bg-1">
          <img id="bg-1-1" src="img/banner/bg1/bg-1-1.png"  alt=""/>
          <div className="bg-section-1">
            <span id="bg-1-text1">Conoce nuestros</span>
            <span id="bg-1-text2">nuevos métodos de pago</span>
            <p id="bg-1-p">
              <img src="img/banner/bg1/BANNER-HOME_1_logo-yape.png" alt="" id="img-yape"/>
              <img src="img/banner/bg1/BANNER-HOME_1_logo-plin.png" alt="" id="img-plin"/>
              <img src="img/banner/bg1/BANNER-HOME_1_tarjetas.png" alt="" id="img-tarjeta"/>
            </p>
            <a id="bg-1-btn-0" href="https://msextranet.web.app/auth/acceso" className="btn-primary">Pagar</a>
          </div>
          <div className="bg-section-2">
            <img id="bg-1-2" src="img/banner/bg1/bg-1-2.png" alt=""/>
            <img id="bg-1-3-1" src="img/banner/bg1/bg-1-3.png" alt=""/>
            <img id="bg-1-4" src="img/banner/bg1/bg-1-4.png" alt=""/>
            <img id="bg-1-5-1" src="img/banner/bg1/bg-1-5.png" alt=""/>
            <img id="bg-1-6-1" src="img/banner/bg1/bg-1-6.png" alt=""/>
            <img id="bg-1-7-1" src="img/banner/bg1/bg-1-7.png" alt=""/>
            <img id="bg-1-8" src="img/banner/bg1/bg-1-8.png" alt=""/>
            <img id="bg-1-9-0" src="img/banner/bg1/personaHome0.png" alt=""/>
          </div>
        </li>
        <li className="anim-slide bg-1">
          <img id="bg-1-1" src="img/banner/bg1/bg-1-1.png" alt=""/>
          <div className="bg-section-1">
            <span id="bg-1-text1">¿Quieres realizar tus</span>
            <span id="bg-1-text2">pagos?</span>
            <p id="bg-1-p">Realízalos dando click en el siguiente botón.</p>
            <a id="bg-1-btn" href="https://msextranet.web.app/auth/acceso" className="btn-primary">Pagar</a>
          </div>
          <div className="bg-section-2">
            <img id="bg-1-2" src="img/banner/bg1/bg-1-2.png" alt=""/>
            <img id="bg-1-3" src="img/banner/bg1/bg-1-3.png" alt=""/>
            <img id="bg-1-4" src="img/banner/bg1/bg-1-4.png" alt=""/>
            <img id="bg-1-5" src="img/banner/bg1/bg-1-5.png" alt=""/>
            <img id="bg-1-6" src="img/banner/bg1/bg-1-6.png" alt=""/>
            <img id="bg-1-7" src="img/banner/bg1/bg-1-7.png" alt=""/>
            <img id="bg-1-8" src="img/banner/bg1/bg-1-8.png" alt=""/>
            <img id="bg-1-9-1" src="img/banner/bg1/personaHome2.png" alt=""/>
          </div>
        </li>
        <li className="anim-slide bg-1">
          <img id="bg-1-1" src="img/banner/bg1/bg-1-1.png" alt=""/>
          <div className="bg-section-1">
            <span id="bg-1-text1">Compra en cuotas,</span>
            <span id="bg-1-text2">desde 0% de interés ahora.</span>
            <p id="bg-1-p">Financiamos eso que siempre quisiste inmediatamente.</p>
            <a id="bg-1-btn" href="https://miscuotas.com/comoFunciona" className="btn-primary">Cómo funciona</a>
          </div>
          <div className="bg-section-2">
            <img id="bg-1-2" src="img/banner/bg1/bg-1-2.png" alt=""/>
            <img id="bg-1-3" src="img/banner/bg1/bg-1-3.png" alt=""/>
            <img id="bg-1-4" src="img/banner/bg1/bg-1-4.png" alt=""/>
            <img id="bg-1-5" src="img/banner/bg1/bg-1-5.png" alt=""/>
            <img id="bg-1-6" src="img/banner/bg1/bg-1-6.png" alt=""/>
            <img id="bg-1-7" src="img/banner/bg1/bg-1-7.png" alt=""/>
            <img id="bg-1-8" src="img/banner/bg1/bg-1-8.png" alt=""/>
            <img id="bg-1-9-2" src="img/banner/bg1/personaHome1.png" alt=""/>
          </div>
        </li>
        <li className="anim-slide bg-1">
          <img id="bg-1-1" src="img/banner/bg1/bg-1-1.png" alt=""/>
          <div className="bg-section-1">
            <span id="bg-1-text1">Compra ahora,</span>
            <span id="bg-1-text2">paga despues en cuotas.</span>
            <p id="bg-1-p">Muchas tiendas para que compres hoy sin complicaciones.</p>
            <p id="bg-2-p" className="paga mt-5">
              <strong className="pt-3">Paga con:</strong> 
              <img src="img/banner/bg1/BANNER-HOME_1_logo-yape.png" alt="" id="img-yape-1"/>
              <img src="img/banner/bg1/BANNER-HOME_1_logo-plin.png" alt="" id="img-plin-1"/>
              <img src="img/banner/bg1/BANNER-HOME_1_tarjetas.png" alt="" id="img-tarjeta-1"/>
            </p>
            <a id="bg-1-btn-1" href="https://miscuotas.com/comoFunciona" className="btn-primary">
              Cómo funciona
            </a>
          </div>
          <div className="bg-section-2">
            <img id="bg-1-2" src="img/banner/bg1/bg-1-2.png" alt=""/>
            <img id="bg-1-3" src="img/banner/bg1/bg-1-3.png" alt=""/>
            <img id="bg-1-4" src="img/banner/bg1/bg-1-4.png" alt=""/>
            <img id="bg-1-5" src="img/banner/bg1/bg-1-5.png" alt=""/>
            <img id="bg-1-6" src="img/banner/bg1/bg-1-6.png" alt=""/>
            <img id="bg-1-7" src="img/banner/bg1/bg-1-7.png" alt=""/>
            <img id="bg-1-8" src="img/banner/bg1/bg-1-8.png" alt=""/>
            <img id="bg-1-9-3" src="img/banner/bg1/personaHome3.png" alt=""/>
          </div>
        </li>
        <nav className="anim-arrows">
          <span className="anim-arrows-prev fas"></span>
          <span className="anim-arrows-next fas"></span>
        </nav>
      </ul>
    </section>
  )
}