export function SectionDetails () {
  return (
    <section className="section section-details">
      <div className="container">
        <div className="details">
          <article className="detail">
            <div className="detail__text">
              <h3>Descubre la nueva manera de pagar mejor.</h3>
              <p>Desde 0% de intereses, sin sorpresas, ni costes adicionales; si pagas tus cuotas a tiempo.</p>
            </div>
                <img srcSet="img/como_funciona/detail-1.png" className="imgDetail" alt="My default image" />
          </article>
          <article className="detail">
            <div className="detail__text">
              <h3>Las mejores marcas ya ofrecen Mis Cuotas</h3>
              <p>Compra ahora y paga después en cuotas, en varias tiendas físicas y online ¡Encuéntralas en nuestro
                directorio y compra hoy todo aquello que necesitas y quieres!</p>
            </div>
                <img srcSet="img/como_funciona/detail-2.png" className="imgDetail" alt="" />
          </article>
        </div>
        <article className="datils__contact">
          <div className="details__contact__text">
            <img src="img/como_funciona/user.png" alt=""/>
            <p>
              <span>¿Tienes alguna consulta?</span> Encuentra las respuestas en nuestra sección de preguntas frecuentes.
              Si no hemos resuelto tu duda, ponte en contacto con nosotros.
            </p>
          </div>
          <a >Contáctenos</a>
        </article>
      </div>
    </section>
  )
}