import { RespuestaTerminosCondi } from "../RespuestaTerminosCondi/RespuestaTerminosCondi"

export function SectionTerminoCondiciones (){

    return(
      <>
      <section className="section section-faqs">
      <div className="container">
          <article>
            <div className="faqs-content">
              <RespuestaTerminosCondi i={1} title={"Aceptación"}
                contenido={["El presente documento regula el uso de la Plataforma Digital de Evaluación Crediticia y la Web de la que es titular la Empresa. Por medio de la Plataforma Digital de Evaluación Crediticia, los usuarios del comercio afiliado podrán acceder a un financiamiento inmediato ofrecido por la Empresa con la finalidad de comprar los productos que ofrecen aquellos comercios a través de su página web o tiendas físicas afiliadas. Asimismo, por medio de la Web, los usuarios podrán revisar y gestionar el pago de sus cuotas referidas al crédito solicitado a la Empresa, así como utilizar este espacio para la atención de reclamos y sugerencia del servicio prestado.",
                            "El ingreso a la Plataforma Digital de Evaluación Crediticia y a la Web atribuyen automáticamente la condición de usuario del mismo e implica la aceptación plena y sin reservas de todas y cada una de las disposiciones incluidas en este aviso que puede sufrir modificaciones, en cualquier momento y sin previo aviso. En ese sentido, el usuario reconoce y acepta que la Plataforma Digital de Evaluación Crediticia, en cualquier momento, podrá interrumpir, desactivar, modificar o cancelar cualquiera de los elementos que la componen o el acceso a la misma."]} />
              
              <RespuestaTerminosCondi i={2} title={"Objeto"}
                contenido={["El objeto del presente documento es regular el uso de la Plataforma Digital de Evaluación Crediticia y la Web por medio de la cual la Empresa presta servicios financieros a favor de los usuarios que los solicitan. Por medio de los Términos y Condiciones, el usuario manifiesta su conformidad para que la Empresa pueda recabar y tratar sus datos personales y demás información requerida para la evaluación del financiamiento solicitado. ",
                            "A través de la Plataforma Digital de Evaluación Crediticia, el usuario podrá solicitar el financiamiento de manera electrónica, rápida y segura, previa evaluación y aprobación de la Empresa. La Empresa realizará las consultas e investigaciones necesarias para conocer el perfil crediticio del usuario, utilizando la información brindada por este, así como aquella que se encuentran en las fuentes accesibles al público u otras que se encuentren detalladas en la Política de Privacidad y Protección de Datos Personales. La Empresa se reserva el derecho de aprobar o rechazar las solicitudes presentadas por los usuarios, siendo obligación del comercio afiliado brindarle otras opciones de pago a los usuarios cuyas solicitudes han sido rechazadas.",
                            "De ser aprobado, el servicio de financiamiento se regulará por medio del Contrato de Crédito (en adelante, el “Contrato”) que es aceptado y suscrito por el usuario de manera manuscrita, o a través de su cuenta u otros indicadores electrónicos, obligándose a cumplir con el pago de las cuotas pactadas en su solicitud, intereses, comisiones, penalidades y demás gastos establecidos en el Contrato y en la Hoja Resumen, que formará parte integrante del Contrato. En ese sentido, cualquier pago aplicable al financiamiento brindado por la Empresa será informado al usuario antes de su aceptación, en la Hoja Resumen: (i) la cuota inicial que deberá ser pagada a la compra del producto, a fin de acceder al financiamiento; (ii) las cuotas posteriores deberán ser pagadas conforme el cronograma de pago establecido en la hoja resumen, (iii) intereses, (iv) comisiones por el uso de la Plataforma Digital de Evaluación Crediticia, (v) demás cargos que se generen en el crédito.","El usuario reconoce y acepta, de manera expresa, incondicional e irrevocable y bajo su propia responsabilidad, que el uso de la Plataforma Digital de Evaluación Crediticia para manifestar su voluntad de aceptación del Contrato, constituye un mecanismo idóneo para la celebración del referido Contrato, de conformidad con lo establecido en el artículo 141 y 141-A del Código Civil."  ]} 
                            />
              
              <RespuestaTerminosCondi i={3} title={"Capacidad para contratar"}
                contenido={["Los servicios financieros ofrecidos por la Empresa a través de la Plataforma Digital de Evaluación Crediticia están dirigidos a aquellos usuarios que cuenten con capacidad de ejercicio plena y capacidad legal para contratar, conforme lo establecido en el Código Civil. Estarán excluidos de obtener el financiamiento, aquellos usuarios que no cuenten con dicha capacidad, así como aquellos usuarios que se encuentren impedidos parcial o totalmente de acceder al crédito ofrecido por la Empresa.",
                            "Los usuarios que solicitan el financiamiento de la Empresa declaran ser mayores de 18 años de edad y que se encuentren facultados para vincularse a las obligaciones requeridas por la Empresa para la prestación de su servicio. En caso ser menor de 18 años de edad, deberá tener la supervisión de sus padres y/o apoderados, ya que la Empresa no puede controlar que menores de edad hagan uso de la Plataforma Digital de Evaluación Crediticia ni las compras que realicen.",
                            "La Empresa no se responsabiliza por las acciones que realicen los menores de edad a través de la Plataforma Digital de Evaluación Crediticia, por ello se comunica a los usuarios que serán los padres y/o tutores los únicos responsables de la navegación y compra de productos por menores de edad utilizando la Plataforma Digital de Evaluación Crediticia.",
                            "Asimismo, el usuario que solicita el financiamiento a la Empresa declara que: 1) Cuenta con capacidad para contratar y vincularse contractualmente con la Empresa en atención a los Términos y Condiciones. 2) Goza de una buena condición con las entidades financieras. 3) Que utilizará la Plataforma Digital de Evaluación Crediticia conforme las leyes vigentes, los Términos y Condiciones, las Políticas de Privacidad y Protección de Datos Personales, la moral, las buenas costumbres y el orden público. Caso contrario, responderá frente a los reclamos, denuncias, demandas y demás interpuestas por la Empresa y/o terceros que se vean afectados por sus actuaciones o las de su representada."]} />
              
              <RespuestaTerminosCondi i={4} title={"Condiciones de acceso a la Plataforma Digital"}
                contenido={["La Plataforma Digital de Evaluación Crediticia y la Web son de acceso libre y gratuito, no obstante, la Empresa podría requerir el pago de una comisión por el uso de la Plataforma Digital de Evaluación Crediticia a los usuarios a los que se les han aprobado el crédito; asimismo, para la utilización de algunos de los servicios ofrecidos, podrá requerir el previo llenado de los formularios correspondientes. La Empresa se reserva el derecho de negar o restringir en cualquier momento al usuario el acceso a la Plataforma Digital de Evaluación Crediticia cuando exista una causal relacionada con la seguridad, privacidad o cualquiera que resulte razonable para ello. Por ello, el usuario se obliga a brindar información, real, exacta y verdadera para el uso de la Plataforma Digital de Evaluación Crediticia de conformidad con las leyes vigentes y el presente documento; caso contrario, el usuario responderá frente a la Empresa o frente a terceros, por cualquier daño y/o perjuicio que pudiera causarse como consecuencia del incumplimiento de dicha obligación.",
                            "El usuario declara estar informado que la Empresa cobrará una comisión por uso de la Plataforma Digital de Evaluación Crediticia siempre que su solicitud de crédito sea aprobada. El valor de esta comisión será determinado de acuerdo a la cantidad de las cuotas que EL CLIENTE requiera para financiar su crédito. No se cobrará la comisión por uso de Plataforma Digital de Evaluación Crediticia para aquellos créditos que sean financiados en una (1) o dos (2) cuotas.",
                            "El usuario garantiza la autenticidad y actualidad de todos aquellos datos e información personal que comunique a la Empresa y será el único responsable de las manifestaciones falsas, viciadas, inexactas o de mala fe que realice. La Empresa no se responsabiliza bajo ninguna circunstancia por los datos presentados por el usuario, reservándose la facultad de requerir información y/o adicional comprobatoria.",
                            "La Empresa no garantiza ni es responsable por la disponibilidad, utilidad, operatividad y continuidad de la Plataforma Digital de Evaluación Crediticia y la Web, en relación con ninguna actividad específica. Asimismo, la Empresa no se responsabiliza por la pérdida, daño o perjuicio alguno, sin importar su naturaleza o alcance, a consecuencia de la falta de disponibilidad, utilidad, operatividad y continuidad de la Plataforma Digital de Evaluación Crediticia, la Web y/o del servicio financiero prestado",
                            "En definitiva, los usuarios que accedan a la Plataforma Digital de Evaluación Crediticia y la Web pueden visualizar los contenidos y efectuar, en su caso, copias privadas autorizadas siempre que los elementos reproducidos no sean cedidos posteriormente a terceros, ni se instalen a servidores conectados a redes, ni sean objeto de ningún tipo de explotación. Asimismo, el usuario que acceda a la Plataforma Digital de Evaluación Crediticia y la Web autoriza expresamente a la Empresa a acceder a su dispositivo móvil, enviar notificaciones al dispositivo, enviar mensajes de texto o WhatsApp, o correos electrónicos, o realizar llamadas desde el dispositivo, con fines relacionados al servicio de financiamiento.",
                            "Todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en la Plataforma Digital de Evaluación Crediticia y la Web son de propiedad de la Empresa, sin que pueda entenderse que el uso o acceso al mismo atribuya al usuario derecho alguno sobre los mismos. La distribución, modificación, cesión o comunicación pública de los contenidos y cualquier otro acto que no haya sido expresamente autorizado por la Empresa, titular de los derechos de explotación, quedan prohibidos.",
                            "El establecimiento de un hipervínculo no implica en ningún caso la existencia de relaciones entre la Empresa y el propietario del sitio web en la que se establezca, ni la aceptación y aprobación por parte de la Empresa de sus contenidos o servicios. Aquellas personas que se propongan establecer un hipervínculo previamente deberán solicitar autorización por escrito a la Empresa. En todo caso, el hipervínculo únicamente permitirá el acceso a la página de inicio de la Plataforma Digital de Evaluación Crediticia o de la Web, asimismo deberá abstenerse de realizar manifestaciones o indicaciones falsas, inexactas o incorrectas sobre la Empresa, o incluir contenidos ilícitos, contrarios a las buenas costumbres y/o al orden público.",
                            "La Empresa no se responsabiliza del uso que cada usuario dé a los materiales puestos a disposición en la Plataforma Digital de Evaluación Crediticia ni en la Web, ni de las actuaciones que realice en base a los mismos."]} />
              
              <RespuestaTerminosCondi i={5} title={'Modificación de los Términos y Condiciones'}
                contenido={["La Empresa se reserva expresamente el derecho a modificar, actualizar o ampliar en cualquier momento los Términos y Condiciones. Estas acciones serán debidamente publicadas en la página web www.miscuotas.com a efectos de que el usuario se encuentre informado, siendo su responsabilidad revisar los Términos y Condiciones vigentes al momento de la navegación.",
                            "El usuario tendrá el derecho de no aceptar las variaciones de los Términos y Condiciones vigentes, y podrá optar por dejar el uso de la Plataforma Digital de Evaluación Crediticia y de la Web."]} />

              <RespuestaTerminosCondi i={6} title={'Registro de usuario'}
                contenido={["Para navegar en la Plataforma Digital de Evaluación Crediticia, el usuario no necesita registrarse. Sin embargo, para acceder al financiamiento de la Empresa será imprescindible que se registre en la Plataforma Digital de Evaluación Crediticia con un correo electrónico y número de celular personal de titularidad del usuario. Para acceder al registro del usuario, se deberán aceptar, previamente y de manera obligatoria, los Términos y Condiciones y las Políticas de Privacidad y Protección de Datos Personales.",
                            "El usuario podrá acceder al registro en la Plataforma Digital de Evaluación Crediticia, solicitando su acceso a un asesor de ventas en las tiendas físicas del comercio afiliado. El registro del usuario se verificará completando el formulario que requiera la Plataforma Digital de Evaluación Crediticia y su posterior envío, el cual será validado con el código de confirmación que le llegue al teléfono o correo electrónico que brinde el usuario. Una vez aprobado el crédito, el usuario es responsable de ingresar los siguientes datos personales reales y actualizados necesarios para el registro y evaluación de acceso al financiamiento: nombres, apellidos, documento de identidad, dirección, correo electrónico, celular, ingresos mensuales, género, estado civil, número de personas que dependen del usuario, trabajo, profesión/ocupación, nombre de la empresa donde trabaja el usuario, RUC de la empresa, imagen (foto) del usuario. El usuario no deberá ingresar datos falsos, incorrectos o que correspondan a otra persona, sobre la cual no tiene representación acreditada.",
                            "La Empresa no es responsable por los errores ortográficos y/o tipográficos incurridos por el usuario, por dolo, negligencia o desconocimiento en el uso de la Plataforma Digital de Evaluación Crediticia. En el caso de las imágenes requeridas para el registro o validación de información, el usuario autoriza expresamente el uso de su imagen para los fines materia del Contrato de Crédito y del servicio de financiamiento prestado por la Empresa, siendo el usuario el único responsable por la veracidad y autenticidad de que la imagen tomada pertenece al solicitante y titular de la cuenta generada.",
                            "En el caso del uso de la Web, el usuario es el único responsable de las cuentas, códigos de confirmación, claves, contraseñas, números confidenciales, datos contables e información personal utilizada para el acceso a los servicios ofrecidos en www.miscuotas.com, los cuales deberá mantener bajo absoluta reserva y confidencialidad bajo su entera responsabilidad. Asimismo, cada usuario será responsable de las acciones realizadas a través de su cuenta creada en la Web, sin excepción alguna, de tal manera que cualquier acción realizada a través de su cuenta se presumirá que fue realizada por el titular de la cuenta.",
                            "La Empresa no será responsable por los daños, perjuicios y/o divulgaciones de los datos personales del usuario, así como de la usurpación de su cuenta en la Plataforma Digital de Evaluación Crediticia y la Web. Si el usuario tiene indicios de que un tercero tiene acceso a su cuenta o ha perdido u olvidado su clave, contraseña o código de confirmación, deberá notificarlo inmediatamente a la Empresa al correo servicioalcliente@miscuotas.com, señalando los motivos de su comunicación.",
                            "La Empresa podrá solicitar al usuario el cambio de usuario y contraseña, o podrá brindarle un nuevo código de confirmación para que pueda acceder a su cuenta, cuando considere que su cuenta no es segura o si recibe alguna queja o denuncia respecto al nombre de un usuario que vulnere los derechos de terceros."
                            ]} />
                  
              <RespuestaTerminosCondi i={7} title={'Instalación y uso de aplicativo en teléfono celular del usuario'}
                contenido={["LA COMPAÑÍA informa a EL CLIENTE que luego de aprobado el crédito y realizada la compra del producto seleccionado en el COMERCIO AFILIADO, se le instalará - con el consentimiento previo, expreso e informado de EL CLIENTE - un aplicativo en el teléfono celular de su titularidad. Si EL CLIENTE adquiere un teléfono celular en el COMERCIO AFILIADO, el aplicativo de bloqueo se instalará en este nuevo dispositivo, que se presume es de su titularidad. Este aplicativo se activará, bloqueando el teléfono celular de EL CLIENTE, únicamente cuando este se retrase en el pago de las cuotas comprometidas en la Hoja Resumen y Cronograma de Pagos vigentes. En ese sentido, LA COMPAÑÍA quedará autorizada por EL CLIENTE a utilizar el aplicativo instalado en el teléfono celular de su titularidad para realizar las siguientes acciones de cobranza:",
                            " a) Envío de avisos recordatorios al teléfono celular de titularidad de EL CLIENTE, sobre la(s) cuota(s) que se encuentran pendientes de pago luego del primer día de atraso.",
                            " b) De no obtener respuesta a los avisos recordatorios, LA COMPAÑÍA le informa a EL CLIENTE que se procederá con el bloqueo preventivo de su teléfono celular, por el periodo de 2 a 48 horas. El bloqueo del celular se efectuará a partir del quinto día posterior al vencimiento de la segunda cuota.",
                            " c) El proceso de bloqueo preventivo se podrá repetir hasta que EL CLIENTE pague la(s) cuotas pendientes de cancelar.",
                            " d) De persistir el incumplimiento de pago, LA COMPAÑÍA realizará el bloqueo permanente del teléfono celular de titularidad de EL CLIENTE, a partir del décimo día posterior al vencimiento de la segunda cuota.",
                            " e) El bloqueo temporal y permanente del teléfono celular de EL CLIENTE no afectarán la realización de llamadas de emergencia.",
                            " f) LA COMPAÑÍA no se hace responsable por los casos de pérdida, robo, sustracción u otros de los teléfonos celulares que adquirió EL CLIENTE por medio del financiamiento otorgado. En ese sentido, no es función ni responsabilidad de LA COMPAÑÍA realizar el bloqueo de un teléfono celular en los casos antes mencionados."]} />

              <RespuestaTerminosCondi i={8} title={'Usos permitidos'}
                contenido={["El usuario se compromete expresamente a hacer un uso adecuado y correcto de los contenidos y servicios de la Plataforma Digital de Evaluación Crediticia y la Web, entre otros a:",
                            "a) No utilizar la Plataforma Digital de Evaluación Crediticia ni en la Web para fines o efectos ilícitos o contrarios a lo establecidos en los Términos y Condiciones, lesivos de los intereses o derechos de la Empresa y/o terceros.",
                            "b) No destruir, alterar, inutilizar o dañar de alguna manera los programas, hipervínculos, formularios o cualquier documento electrónico publicado en la Plataforma Digital de Evaluación Crediticia ni en la Web.",
                            "c) No obstaculizar el acceso a otros usuarios mediante el consumo masivo de los recursos informáticos a través de los cuales la Empresa presta sus servicios financieros, ni realizar acciones que podrían dañar, interrumpir o generar errores en dichos servicios o sistemas.",
                            "d) No introducir en la red algún virus informático o realizar actuaciones susceptibles de alterar, estropear, interrumpir o generar errores o daños en los documentos electrónicos, datos o sistemas físicos y lógicos de la Empresa y/o de terceras personas; así como obstaculizar el acceso de otros usuarios al sitio web y a sus servicios mediante el consumo masivo de los recursos informáticos a través de los cuales la Empresa presta sus servicios.",
                            "e) No intentar acceder a las cuentas de correo electrónico de otros usuarios o a áreas restringidas de los sistemas informáticos de la Empresa o de terceros y, en su caso, extraer información.",
                            "f) No brindar datos personales de otros usuarios para acceder al crédito ofrecido por la Empresa.",
                            "g) No vulnerar los derechos de propiedad intelectual o industrial, así como violar la confidencialidad de la información de la Empresa o de terceros.",
                            "h) No suplantar la identidad de otro usuario, de alguna autoridad o de un tercero.",
                            "i) No reproducir, copiar, distribuir, poner a disposición o de cualquier otra forma comunicar públicamente, transformar o modificar los contenidos, a menos que se cuente con la autorización del titular de los correspondientes derechos o ello resulte legalmente permitido. ",
                            "j) No recabar datos con finalidad publicitaria y de remitir publicidad de cualquier clase y comunicaciones con fines de venta u otras de naturaleza comercial sin que medie previa solicitud o consentimiento del destinatario.",
                            "k) Aceptar que debido a problemas técnicos, tráfico, horarios, o razones ajenas al control de la Empresa, los servicios podrán demorarse o interrumpir indefinidamente en cuyo caso, la Empresa tendrá la opción de enviar una comunicación señalando la fecha real de desembolso de crédito y se realizará el cómputo del plazo correspondiente, y siempre y cuando ocurra dentro de 96 horas de haber sido aprobada la solicitud de préstamo, el Usuario se compromete a aceptar el préstamo y pagarlo según las condiciones pactadas en el contrato.",
                            "l) Advertir y comunicar cualquier tipo de error o inexactitud que el usuario advierta respecto a alguna comunicación de la Empresa, y esta comunicación deberá ser efectuada en cuanto tenga conocimiento de esta o dentro de 24 horas en circunstancias extremas. Una vez reportado el hecho a la Empresa, este será atendido según los controles internos establecidos.",
                            "Si el usuario incumple alguna de estas disposiciones o utilice la Plataforma Digital de Evaluación Crediticia o la Web para perjudicar los fines prestado por la Empresa u a terceros, la Empresa podrá realizar las siguientes acciones:",
                            "◉Amonestación al usuario.",
                            "◉Suspensión temporal de la cuenta del usuario.",
                            "◉Cancelación definitiva de la cuenta del usuario.",
                            "◉Acciones por responsabilidades administrativas, civiles o penales.",
                            ]} />

              <RespuestaTerminosCondi i={9} title={'Prohibiciones'}
                contenido={["Los usuarios solo podrán utilizar la Plataforma Digital de Evaluación Crediticia para solicitar el financiamiento de compras realizadas a través de la página web y tiendas físicas de los comercios afiliados a la Empresa. En ese sentido, los usuarios se comprometen a no utilizar la Plataforma Digital de Evaluación Crediticia ni la Web para procesar solicitudes ni pagos relacionados a la compra y/o venta de productos y/o servicios ilegales incluyendo, pero no limitándose a:",
                            "◉Productos de contrabando o artículos falsificados.",
                            "◉Bienes embargados.",
                            "◉Drogas ilegales.",
                            "◉Material ofensivo o discriminatorio. ",
                            "◉Materiales, o contenidos para adultos. ",
                            "◉Dinero en efectivo.",
                            "◉Daños, pérdidas, sanciones o multas de cualquier tipo.",
                            "◉Costos o comisiones por encima del precio normal de los bienes o servicios del comercio (más impuestos aplicables) o cargos que los usuarios no hayan aprobado específicamente",
                            "◉Montos vencidos, o montos que cubran cheques devueltos con suspensión de pagos.",
                            "◉Ventas hechas por terceros o entidades haciendo negocio en otras industrias distintas al comercio afiliado. ",
                            "◉Montos que no representen ventas de buena fe de bienes o servicios del negocio del comercio afiliado.",
                            "◉Transacciones de negocios ilegales.",
                            "◉Lavado de dinero o financiamiento de actividades terroristas.",
                            "◉Ningún otro de los artículos considerados prohibidos por la Empresa y la normativa vigente."]} />
            
              <RespuestaTerminosCondi i={10} title={'Limitación de responsabilidad e indemnidad'}
                contenido={["La Empresa ha implementado en la Plataforma Digital de Evaluación Crediticia y en la Web todas las medidas de seguridad necesarias para su óptimo funcionamiento desde el ingreso de las solicitudes de los usuarios hasta la aprobación del financiamiento ofrecido. Sin embargo, la Empresa no garantiza:",
                            "◉La imposibilidad de acceso a la Plataforma Digital de Evaluación Crediticia, a la Web o la falta de veracidad, exactitud, exhaustividad y/o actualidad de los contenidos, así como la existencia de vicios y defectos de toda clase de los contenidos transmitidos, difundidos, almacenados, puestos a disposición a los que se haya accedido a través de la Plataforma Digital de Evaluación Crediticia, de la Web o de los servicios que se ofrecen.",
                            "◉La presencia de virus o de otros elementos en los contenidos que puedan producir alteraciones en los sistemas informáticos, documentos electrónicos o datos de los usuarios.",
                            "◉El incumplimiento de las leyes, la buena fe, el orden público, los usos del tráfico y el presente aviso legal como consecuencia del uso incorrecto la Plataforma Digital de Evaluación Crediticia y de la Web. En particular, y a modo ejemplificativo, la Empresa no se hace responsable de las actuaciones de terceros que vulneren derechos de propiedad intelectual e industrial, secretos empresariales, derechos al honor, a la intimidad personal y familiar y a la propia imagen, así como la normativa en materia de competencia desleal y publicidad engañosa, que pudieran afectar el proceso de financiamiento, motivo por el cual el usuario exime de toda y cualquier responsabilidad a la Empresa por cualquiera de las circunstancias descritas precedentemente.",
                            "◉Los errores y fallas técnicas que puedan incluir los materiales e información de la Plataforma Digital de Evaluación Crediticia y la Web. Ni la Empresa ni los terceros involucrados en el diseño, administración y actualización de la Plataforma Digital de Evaluación Crediticia y de la Web serán responsables de los daños que eventualmente llegaren a sufrir los usuarios directa o indirectamente, o como consecuencia del acceso o el uso de la misma. La Empresa no garantiza que el acceso a la Plataforma Digital de Evaluación Crediticia y a la Web sean permanentes o libre de errores, o se encuentren libres de virus o de otros componentes dañinos. En consecuencia, la Empresa no será responsable de los daños sufridos por el uso de la Plataforma Digital de Evaluación Crediticia ni de la Web, causados, entre otras circunstancias, por su uso o falta de acceso a ellas; el uso o falta de acceso a un sitio web vinculado (“links”); fallas en el desempeño de la Plataforma Digital de Evaluación Crediticia y la Web; errores en el mismo; omisiones; interrupciones; demoras en la operación o en la transmisión; fallas en la línea, y similares.",
                            "◉La Empresa no se hace responsable por la calidad, seguridad, integridad, utilidad e idoneidad de los productos o servicios contratados por el usuario, pagados a través del financiamiento ofrecido por la Empresa, ni de la veracidad o precisión en la descripción de dichos productos o servicios, mostrados o distribuidos a través del comercio afiliado. En este sentido, la Empresa no responderá por las posibles reclamaciones que puedan formular los usuarios relacionadas con la calidad o adecuación de los productos o bienes contratados con los comercios electrónicos afiliados.",
                            "En ese sentido, la Empresa se compromete a realizar sus mejores esfuerzos para evitar cualquier error en los contenidos que pudieran aparecer en la Plataforma Digital de Evaluación Crediticia y en la Web. En cualquier caso, el usuario acepta que la Empresa no será responsable por los daños y perjuicios que se puedan derivar de interferencias, omisiones, interrupciones, errores, retrasos, bloqueos, virus informáticos, averías o desconexiones en el funcionamiento operativo de la Plataforma Digital de Evaluación Crediticia y de la Web.",
                            "Finalmente, el usuario acepta eximir de responsabilidad a la Empresa ante posibles reclamaciones, demandas, sanciones, o multas derivadas del mal uso de la solución de la Empresa, de la violación de los presentes Términos y Condiciones o de la vulneración de derechos de terceros."]} />

              <RespuestaTerminosCondi i={11} title={'Responsabilidad de los usuarios'}
                contenido={["Los usuarios reconocen y aceptan que las compras de bienes, mercancías, productos y/o servicios son operaciones entre el usuario y el comercio afiliado, y no directamente con la Empresa. La Empresa no es parte de las operaciones de compra/venta y no es un comprador o vendedor en conexión con ninguna transacción realizada entre los usuarios y los comercios afiliados.",
                            "Es responsabilidad de los usuarios autorizar y cancelar la cuota inicial y demás cuotas de su compra utilizando el financiamiento ofrecido por la Empresa, ya sea a través de la Plataforma Digital, de las tiendas físicas del comercio afiliado o agentes bancarios, dentro del periodo de tiempo aprobado en la Hoja Resumen y el Contrato.",
                            "La Empresa no se responsabiliza por los reembolsos y reclamaciones que exijan los usuarios a los comercios afiliados por los productos o servicios adquiridos, ya que la Empresa no participa como parte en dicha transacción. Todo reclamo o consulta al respecto se realizará directamente en el comercio afiliado en donde se efectuó la compra."]} />
              
              <RespuestaTerminosCondi i={12} title={'Propiedad Intelectual '}
                contenido={["La Empresa mantiene la Plataforma Digital de Evaluación Crediticia y la Web para brindar información acerca del servicio de financiamiento ofrecidos a los usuarios de sus comercios afiliados. Las marcas, nombres comerciales, reseñas, gráficos, ilustraciones, fotografías, dibujos, diseños, software, música, sonido, videos y cualquier otros medios o formas de difusión de contenidos, información o conocimientos propiedad que aparezca en la Plataforma Digital de Evaluación Crediticia y la Web, así como los contenidos están protegidas legalmente a favor de la Empresa de conformidad con las disposiciones legales sobre propiedad industrial y sobre derechos de autor, sin que puedan entenderse cedidos al usuario ninguno de los derechos de explotación sobre los mismos más allá de lo estrictamente necesario para el correcto uso de ambas plataformas. En consecuencia, queda prohibido ser utilizadas, difundidas, exhibidas, explotadas, comercializadas, modificadas, copiadas, reproducidas, transmitidas o distribuidas de ninguna manera (parcial o totalmente) ni por cualquier medio, salvo previa autorización escrita y expresa de la Empresa.",
                            "Asimismo, queda expresamente prohibida la utilización o reproducción o copia de cualquier marca registrada, nombre o logotipo que figure en la Plataforma Digital de Evaluación Crediticia o en la Web sin la autorización previa y por escrito de la Empresa, así como la utilización del software que esta opera con excepción de aquellos usos permitidos bajo estos Términos y Condiciones.",
                            "Las ideas, opiniones, sugerencias y comentarios que sean enviadas en forma espontánea y sin previa solicitud por los usuarios de la Plataforma Digital de Evaluación Crediticia y la Web, relativas a productos, servicios o planes de mercadeo, o de cualquier otro tipo serán usadas libremente por la Empresa si así lo estima conveniente, para el desarrollo de nuevos productos o planes de mercadeo. Las referidas comunicaciones no son confidenciales y se presume que no están protegidas por ninguna regulación referente a derechos de autor o a propiedad intelectual, y, por ende, el usuario remitente de tal información, no podrá reclamar indemnización o participación alguna en virtud del legítimo uso comercial que la Empresa de a la misma. Si la información estuviera protegida por algún tipo de regulación sobre derechos de autor o propiedad intelectual, su comunicación espontánea a la Empresa se entenderá como una renuncia total e irrevocable a los derechos y privilegios morales y patrimoniales que tales regulaciones otorgan, y la transferencia de los mismos a la Empresa. En todo caso, sea que la información suministrada esté protegida o no, la misma se convertirá y permanecerá de propiedad de la Empresa, lo cual significa que esta compañía no tratará esta información como confidencial; en consecuencia, el usuario remitente de la información no podrá reclamar judicial ni extrajudicialmente por el uso de esa información, y no tendrá derecho a pago alguno en virtud del uso de la información suministrada."]} />
              
              <RespuestaTerminosCondi i={13} title={'Enlaces con terceros'}
                contenido={["Si la Plataforma Digital de Evaluación Crediticia y la Web dispusieran de enlaces o hipervínculos hacia otros sitios web de terceros, la Empresa declara que no ejerce ningún tipo de control, no garantiza ni respalda el contenido de dichos sitios web ni las políticas de los mismos. En ningún caso la Empresa asumirá responsabilidad alguna por los contenidos de algún enlace perteneciente a una web ajena, ni garantizará la disponibilidad técnica, calidad, fiabilidad, exactitud, veracidad, validez y constitucionalidad de cualquier material o información contenida en cabeza de los hipervínculos u otros lugares de Internet.",
                            "De ser el caso, los enlaces de terceros se proporcionarán solo para informar al usuario sobre la existencia de otras fuentes de información sobre un tema concreto, y la inclusión de un enlace no implica la aprobación de la página web enlazada por parte de la Empresa. En tal sentido, el usuario reconoce que en ningún caso podrá reclamar, denunciar y/o demandar a la Empresa por el contenido de los sitios web de terceros."
                            ]} />
              
              <RespuestaTerminosCondi i={14} title={'Datos Personales'}
                contenido={["La Empresa garantiza la protección de los datos de carácter personal y evitar su alteración, pérdida, tratamiento y/o acceso no autorizado, habida cuenta del estado de la técnica, la naturaleza de los datos almacenados y los riesgos a que están expuestos, todo ello, conforme a lo establecido por la normativa vigente de Protección de Datos Personales.",
                            "Por su parte, los usuarios manifiestan que toda la información proporcionada en la Plataforma Digital de Evaluación Crediticia y la Web es actual, verdadera, completa y exacta. Cada usuario es responsable por la veracidad, exactitud y autenticidad de la información suministrada, y se compromete a mantenerla debidamente actualizada.",
                            "Los distintos tratamientos de datos personales que la Empresa realiza a través de la Plataforma Digital de Evaluación Crediticia y la Web, así como las finalidades de dichos tratamientos serán detallados específicamente en la Política de Privacidad y Protección de Datos Personales publicada en la página web www.miscuotas.com."]} />
              
              <RespuestaTerminosCondi i={15} title={'Confidencialidad'}
                contenido={["La Empresa se compromete expresamente a mantener la confidencialidad de la información y datos personales que proporcione el usuario siempre que tenga dicho carácter conforme a legislación aplicable a la materia en la República del Perú. La Empresa no se obliga a guardar confidencialidad respecto a cualquier otra información señalada por el usuario durante cualquier interacción con la Plataforma Digital de Evaluación Crediticia y la Web o en el proceso de aplicación y/u obtención del financiamiento. Por ello, el usuario autoriza expresamente a la Empresa a usar, publicar, o comunicar públicamente la información no considerada confidencial ingresada mediante el uso de la Plataforma Digital de Evaluación Crediticia y la Web, en términos de lo establecido en la Ley sobre el Derecho de Autor, Decreto Legislativo N° 822."]} />
              
              <RespuestaTerminosCondi i={16} title={'Fuerza mayo'}
                contenido={["La Empresa no será responsable por cualquier retraso o falla en el rendimiento o la interrupción en la prestación de los servicios que pueda resultar directa o indirectamente de cualquier causa o circunstancia más allá de su control razonable, incluyendo, pero sin limitarse a fallas en los equipos o las líneas de comunicación electrónica o mecánica, teléfono u otros problemas de interconexión, virus informáticos, el acceso no autorizado, robo, errores del operador, clima severo, terremotos o desastres naturales, huelgas u otros problemas laborales, guerras, o restricciones gubernamentales.",
                            "El usuario debe cumplir con sus compromisos contractuales plenamente aceptados, de los plazos y tiempos establecidos en la Hoja Resumen y el Contrato, incluso cuando se presenten inconvenientes con la funcionalidad, disponibilidad y operatividad en la Plataforma Digital de Evaluación Crediticia y la Web, aprobación y entrega de préstamos, por responsabilidad de la Empresa o de entidades externas."
                            ]} />
              
              <RespuestaTerminosCondi i={17} title={'Cesión de posición contractual'}
                contenido={["Los usuarios autorizan expresamente la cesión de estos Términos y Condiciones y de su información personal en favor de cualquier persona que (i) quede obligada por estos Términos y Condiciones y/o (ii) que sea el nuevo responsable del banco de datos que contenga su información personal. Luego de producida la cesión, la Empresa no tendrá ninguna responsabilidad con respecto de cualquier hecho que ocurra a partir de la fecha de la cesión. El nuevo responsable del banco de datos asumirá todas y cada una de las obligaciones de la Empresa establecidas en los presentes Términos y Condiciones y en la Política de Privacidad y Protección de Datos Personales respecto del tratamiento, resguardo y conservación de la información personal de los usuarios de la Plataforma Digital de Evaluación Crediticia y la Web."
                ]} />
              
              <RespuestaTerminosCondi i={18} title={'Comunicaciones'}
                contenido={["El usuario acepta expresa, incondicional e irrevocablemente que la dirección de correo electrónico y número de teléfono celular consignados en el formulario de registro serán los medios de contacto oficial entre la Empresa y el usuario, siendo absoluta responsabilidad del usuario verificar que dicho correo electrónico y teléfono celular esté siempre activo y funcional para poder recibir todas las comunicaciones procedentes de la Empresa, así como revisar su bandeja de correo electrónico y/o del teléfono móvil, y mantenerlos activos para la recepción de comunicaciones de la Empresa.",
                            "Los mensajes o comunicaciones de la Empresa a los usuarios sólo pueden provenir de las páginas o cuentas oficiales de la Empresa en redes sociales u otros medios. Si la Empresa identifica que algún usuario está enviando comunicaciones o realizando publicaciones en su nombre, se iniciará las acciones correctivas y legales pertinentes a fin de proteger al resto de usuarios del portal de posibles riesgos de confusión.",
                            "Sin perjuicio de lo mencionado, las notificaciones y comunicaciones de la Empresa con el usuario referidas al servicio financiero prestado serán realizadas:",
                            "a) Mediante la siguiente dirección de correo electrónico: servicioalcliente@miscuotas.com",
                            "b) Enviando el comunicado por escrito a Calle Schell N° 168 – 2do piso, Miraflores, Lima, Perú (Oficina Principal).",
                            "c) Por medio de la página web: www.miscuotas.com",
                            "d) Número de WhatsApp: +51 967 421 523.",
                            "En caso que la Empresa lo considere necesario, podrá enviar comunicaciones al domicilio físico consignado por el usuario en la Plataforma Digital de Evaluación Crediticia o en la Web. Asimismo, también podrá comunicarse con el usuario a los números telefónicos consignados en la Plataforma Digital de Evaluación Crediticia o en la Web, para cuyo efecto, el usuario autoriza a la Empresa a realizar grabaciones de dichas comunicaciones.",
                            "Toda comunicación dirigida por la Empresa al usuario, previa a la aprobación del financiamiento o desembolso del préstamo, por cualquier canal de atención incluyendo, pero sin limitarse a SMS, email, WhatsApp, llamadas telefónicas, no implica ni deberá ser interpretada como una aprobación del financiamiento por parte de la Empresa, sin reserva, exclusión, excepción, restricción, ni limitación alguna, salvo que tal aprobación sea manifestada por la Empresa de manera expresa por escrito. En ese sentido, queda expresamente establecido que la Empresa puede, a su criterio, desestimar el financiamiento, si es que las condiciones crediticias del usuario no se mantienen y/o la Empresa no pueda comprobar la identidad del usuario."
                            ]} />
              
              <RespuestaTerminosCondi i={19} title={'Libro de reclamaciones'}
                contenido={["Conforme a lo establecido en el Código de Protección y Defensa del Consumidor, Ley N° 29571, las Plataformas Digitales ponen a disposición del usuario un Libro de Reclamaciones virtual a fin de que éste pueda registrar sus quejas o reclamos formales sobre los servicios ofrecidos a través del portal. Éste puede ser encontrado en esta dirección: www.miscuotas.com",
                            ]} />
              
              <RespuestaTerminosCondi i={20} title={'Autoridades y requerimientos legales '}
                contenido={["La Empresa resguardará la información de los usuarios de la Plataforma Digital de Evaluación Crediticia y la Web, salvo esta sea requerida por las autoridades judiciales o gubernamentales competentes, en la medida en que discrecionalmente lo entienda necesario, para efectos de investigaciones conducidas por ellas, cuando se trate de investigaciones de carácter penal o de fraude, o las relacionadas con piratería informática, la violación de derechos de autor, infracción de derechos de propiedad intelectual u otra actividad ilícita o ilegal que pueda exponer a la Plataforma Digital de Evaluación Crediticia y la Web, la Empresa o a sus usuarios a cualquier responsabilidad legal.",
                            "Asimismo, la Empresa se reserva el derecho de comunicar información sobre sus usuarios a otros usuarios, a entidades o a terceros, cuando haya motivos suficientes para considerar que la actividad de un usuario sea sospechosa de intentar o cometer un delito o intentar perjudicar a otras personas. Este derecho será utilizado por la Empresa a su entera discreción cuando lo considere apropiado o necesario para mantener la integridad y seguridad de la Plataforma Digital de Evaluación Crediticia, la Web y la de sus usuarios, para hacer cumplir los presentes Términos y Condiciones, y a efectos de cooperar con la ejecución y cumplimiento de la ley."
                            ]} />
              
              
              <RespuestaTerminosCondi i={21} title={'Inexistencia de sociedad o relación laboral'}
                contenido={["La participación de un usuario en la Empresa no constituye ni crea contrato de sociedad, de representación, de mandato, como así tampoco relación laboral alguna entre dicho usuario y la Empresa."
                          ]} />
              
              <RespuestaTerminosCondi i={22} title={'Ley aplicable y jurisdicción'}
                contenido={["Los presentes Términos y Condiciones se rigen por la ley peruana y cualquier disputa que se produzca con relación a la validez, aplicación o interpretación de los mismos, incluyendo la Política de Privacidad y Protección de Datos Personales, será resuelta en los tribunales del Cercado de Lima, renunciando expresamente a cualquier otro fuero o jurisdicción que pueda corresponderles en razón de sus domicilios presentes o futuros.",
                            "Fecha de última actualización: marzo de 2022."]} />
                          
            </div>
          </article>
        </div>
      </section>
        </>
    )
}