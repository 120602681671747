import { TiendaHero } from "../components/TiendaHero/TiendaHero"
import { SectionTiendasProductos } from "../components/SectionTiendasProductos/SectionTiendasProductos"
import { SectionTiendasDestacadas } from "../components/SectionTiendasDestacadas/SectionTiendasDestacadas"
import React from "react";
import { SectionListTiendas } from "../components/SectionListTiendas/SectionListTiendas";
class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}
export function Tiendas () {

  return <>
  <ScrollToTopOnMount />
    <TiendaHero />
    <SectionTiendasDestacadas />
    <SectionListTiendas/>
    <SectionTiendasProductos /> 
  </>
}