import { useState } from "react"

export function SectionPasosWeb () {
  const [tab, setTab] = useState(1)

  const handleSetTab = (tab) => {
    setTab(tab)
  }

  return (
    <section className="section section-pasos">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="title">
              Pasos para pagar en nuestras cuentas de Web
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 ">
            <div className="container-tabs">
              <p className={`container-tabs__buttons ${tab === 1 && 'container-tabs__buttons--active'}`} onClick={() => handleSetTab(1)}>Paso 1</p>
              <p className={`container-tabs__buttons ${tab === 2 && 'container-tabs__buttons--active'}`} onClick={() => handleSetTab(2)}>Paso 2</p>
              <p className={`container-tabs__buttons ${tab === 3 && 'container-tabs__buttons--active'}`} onClick={() => handleSetTab(3)}>Paso 3</p>
              <div className="content-container-tabs">
                <div className={tab === 1 ? 'content content--active' : 'content'}>
                  <div className="content-tab-pasos">
                    <div className="tab-num"><span>1</span></div>
                    <div className="tab-description">
                      <h3>Ingresa tu usuario y contraseña <a  className="enlaceAzul" id="miEnlace" href="https://msextranet.web.app/auth/login"> aquí.</a><br></br> <br /> Si eres cliente nuevo, tu usuario y contraseña la encontrarás en el correo de bienvenida. <br /> <br /> Si tienes algún inconveniente comunícate con nosotros al número de WhatsApp +51 967 421 523. </h3>
                    </div>
                    <div className="tab-img">
                      <img src="img/web/paso-1.png" />
                    </div>
                  </div>
                </div>
                <div className={tab === 2 ? 'content content--active' : 'content'}>
                  <div className="content-tab-pasos">
                    <div className="tab-num"><span>2</span></div>
                    <div className="tab-description">
                      <h3>Selecciona tu cuota<br />En la sección "Tú crédito" </h3>
                    </div>
                    <div className="tab-img">
                      <img src="img/web/paso-2.png" />
                    </div>
                  </div>
                </div>
                <div className={tab === 3 ? 'content content--active' : 'content'}>
                  <div className="content-tab-pasos">
                    <div className="tab-num"><span>3</span></div>
                    <div className="tab-description">
                    <h3>Paga con tarjeta<br />
                        De crédito /débito u otros.</h3>
                    </div>
                    <div className="tab-img">
                      <img src="img/web/paso-3.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}