import ReactOwlCarousel from "react-owl-carousel"

export function SectionDescubre () {
  const options = {
    stagePadding: 1,
    loop:true,
    margin:15,
    nav:false,
    navText: ["<i class=\"fa fa-angle-left\" aria-hidden=\"true\"></i>","<i class=\"fa fa-angle-right\" aria-hidden=\"true\"></i>"],
    dots: true,
    autoplay:false,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    responsive:{
      0:{
        items:1
      },
      600:{
        items:2
      },
      1200:{
        items:3
      }
    }
  
  }

  return (
    <section className="section section-descubre">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="title text-center">Descubre cómo funciona Mis Cuotas:</h2>
            <p className="subtitle">En 3 simples pasos</p>
          </div>
        </div>
        <ReactOwlCarousel {...options} className="owl-carousel owl-theme">
          <article className="card" >
            <span className="card__number">1</span>
            <img src="img/como_funciona/card_1.png" className="card__image" alt =""/>
            <div className="card__text">
              <h3 className="card__title">¿Dónde Comprar?</h3>
              <p>
              Elige lo que quieres comprar. Escoge Mis Cuotas como medio de pago, si te encuentras en una tienda física, indícale al vendedor que quieres financiar tu compra con Mis Cuotas
              </p>
              <a href="/dondeComprar" className="card__btm">Dónde Comprar</a>
            </div>
          </article>
          <article className="card">
            <span className="card__number">2</span>
            <img src="img/como_funciona/card_2.png" className="card__image" alt="" />
            <div className="card__text">
              <h3 className="card__title">¿Cómo comprar?</h3>
              <p>
                Elige lo que quieres comprar.
                Escoge Mis Cuotas como medio de pago (en tienda física, indícale al vendedor que quieres financiar tu
                compra con Mis Cuotas).
              </p>
            </div>
          </article>
          <article className="card">
            <span className="card__number">3</span>
            <img src="img/como_funciona/card_3.png" className="card__image" alt="" />
            <div className="card__text">
              <h3 className="card__title">¿Qué necesitas?</h3>
              <p>
              Sólo necesitas tu DNI físico, un correo electrónico válido y tu celular personal.
              </p>
            </div>
          </article>
        </ReactOwlCarousel>
      </div>
    </section>
  )
}