export function SectionPagosPasos () {
  return (
    <section className="section section-pagos-pasos">
      <div className="container">
        <h2 className="title">Pasos para realizar tu pagos a través de su banco preferido</h2>
        <p>Pagando en nuestra web con cualquier Tarjeta de Crédito o Débito pagando con Pago Efectivo de forma online o acercándote .</p>
        <div className="pagos-pasos__wraper">
          <ul className="pagos-pasos__list">
            <li>
              <img src="img/bancos/check.png" alt="" />
              <h4>Acércate a un agente de tu banco preferido.</h4>
            </li>
            <li>
              <img src="img/bancos/check.png" alt="" />
              <h4>Solicita pagar tu crédito Mis Cuotas.</h4>
            </li>
            <li>
              <img src="img/bancos/check.png" alt="" />
              <h4>Brinda tu número de DNI.</h4>
            </li>
            <li>
              <img src="img/bancos/check.png" alt="" />
              <h4>Realiza el pago y guarda el comprobante.</h4>
            </li>
            <li>
              <img src="img/bancos/check.png" alt="" />
              <h4>Nosotros nos encargamos del resto.</h4>
            </li>
          </ul>
          <aside>
            <img src="img/web/aside.png" className="pagos-pasos__image" alt="" />
          </aside>
        </div>
      </div>
    </section>
  )
}