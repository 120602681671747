export function SectionConsultas () {
  return (
    <section className="section section-consultas">
      <div className="container">
        <picture>
          <source src="img/preguntas_frecuentes/banner-movil.jpg" media="(max-width: 768px)" />
          <img src="img/preguntas_frecuentes/banner.jpg" className="consultas-banner" />
        </picture>
        <article className="consultas-cards">
          <div className="consultas-card">
            <img src="img/preguntas_frecuentes/consulta-1.png" alt="" />
            <h3>Visita un Comercio <br /> Afiliado</h3>
            <p>Los días de Lun - Dom de <br />
              10 am a 10 pm.</p>
          </div>
          <div className="consultas-card">
            <img src="img/preguntas_frecuentes/consulta-2.png" alt="" />
            <h3>Escríbenos al <br /> +51 967421523</h3>
            <p>De Lun - Vie de 9 am a 6pm</p>
          </div>
          <div className="consultas-card">
            <img src="img/preguntas_frecuentes/consulta-3.png" alt="" />
            <h3>Chatea con <br /> un asesor</h3>
            <p>Te ayudamos a resolver tus <br /> dudas o consultas.</p>
          </div>
          <div className="consultas-card">
            <img src="img/preguntas_frecuentes/consulta-4.png" alt="" />
            <h3>Escríbenos <br />
              <a href="mailto:servicioalcliente@miscuotas.com">servicioalcliente@miscuotas.com</a></h3>
            <p>Te responderemos lo más pronto <br />
              posible para poder ayudarte.</p>
          </div>
        </article>
      </div>
    </section>
  )
}