import { useState } from "react"

export function SectionPasos ({imgPaso1,imgPaso2,imgPaso3}) {
  const [tab, setTab] = useState(1)

  const handleSetTab = (tab) => {
    setTab(tab)
  }

  return (
    <section className="section section-pasos">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="title">
              Pasos para comprar con Mis Cuotas:
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 ">
            <div className="container-tabs">
              <p className={`container-tabs__buttons ${tab === 1 && 'container-tabs__buttons--active'}`} onClick={() => handleSetTab(1)}>Paso 1</p>
              <p className={`container-tabs__buttons ${tab === 2 && 'container-tabs__buttons--active'}`} onClick={() => handleSetTab(2)}>Paso 2</p>
              <p className={`container-tabs__buttons ${tab === 3 && 'container-tabs__buttons--active'}`} onClick={() => handleSetTab(3)}>Paso 3</p>
              <div className="content-container-tabs">
                <div className={tab === 1 ? 'content content--active' : 'content'}>
                  <div className="content-tab-pasos">
                    <div className="tab-num"><span>1</span></div>
                    <div className="tab-description">
                      <h3 className="pasosTitulo">Elige el producto que deseas adquirir.</h3>
                      <p>Elige el comercio, el producto y selecciona Mis Cuotas como método  de pago.</p>
                    </div>
                    <div className="tab-img">
                      <img src={imgPaso1} alt=""/>
                    </div>
                  </div>
                </div>
                <div className={tab === 2 ? 'content content--active' : 'content'}>
                  <div className="content-tab-pasos">
                    <div className="tab-num"><span>2</span></div>
                    <div className="tab-description">
                      <h3 className="pasosTitulo">Te evaluamos al instante. </h3>
                      <p>Registra tus datos personales y valida tu identidad (necesitas tu DNI, un correo electrónico válido y tu celular).</p>
                    </div>
                    <div className="tab-img">
                      <img src={imgPaso2} alt=""/>
                    </div>
                  </div>
                </div>
                <div className={tab === 3 ? 'content content--active' : 'content'}>
                  <div className="content-tab-pasos">
                    <div className="tab-num"><span>3</span></div>
                    <div className="tab-description">
                      <h3 className="pasosTitulo">Elige tus condiciones de pago.</h3>
                      <p>Tienes el control. Elija el números de cuotas que funcione para ti y tu presupuesto: desde 0% de intereses en 2 cuotas. La Tienda te entregará tu producto ¡Y Listo!</p>
                    </div>
                    <div className="tab-img">
                      <img src={imgPaso3} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}