export function SectionBancos (){
    return(
        <section className="section section-bancos">
          <div className="container">
            <h2 className="title">Paga tu cuota depositando en nuestras cuentas:</h2>
            <article>
              <div className="bancos-table-header">
                <div className="banco-table-column">
                  <div className="banco-row-table banco-row-title">Titular</div>
                  <div className="banco-row-table banco-row-title">RUC</div>
                </div>
                <div className="banco-table-column">
                  <div className="banco-row-table banco-row-dato">QTC Servicios Financieros S.A.C.</div>
                  <div className="banco-row-table banco-row-dato">20608863762</div>
                </div>
              </div>
              <div className="bancos-table-content">
                <div className="banco-table-column-sticky">
                  <div className="banco-row-table banco-row-title">Entidad</div>
                  <div className="banco-row-table banco-row-title">Nro Cuentas</div>
                  <div className="banco-row-table banco-row-title">CCI</div>
                </div>
                <div className="banco-table-column">
                  <div className="banco-row-dato-table">
                    <table>
                      <tr>
                        <td><img className="imgBanco" src="img/bancos/bcp.png" alt=""/></td>
                        <td><img className="imgBanco" src="img/bancos/bbva.png" alt=""/></td>
                        <td><img className="imgBanco" src="img/bancos/interbank.png" alt=""/></td>
                        <td><img className="imgBanco" src="img/bancos/scotian.png" alt=""/></td>
                      </tr>
                      <tr>
                        <td>193-9859330-0-90</td>
                        <td>0011-0616-0100019751</td>
                        <td>898-300388113-4</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>00219300985933009012</td>
                        <td>011-616-000100019751-05</td>
                        <td>00389800300388113442</td>
                        <td>-</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </article>
          </div>
          <div className="container">
            <h2 className="titleDos">Canales de pago:</h2>
            <article className="bancos-table">
              <div className="bancos-row bancos-head">
                <div className="bancos-col "> </div>
                <div className="bancos-col "><img className="head-banco" src="img/bancos/bcpTable.png" alt=""/></div>
                <div className="bancos-col "><img className="head-banco" src="img/bancos/bbvaTable.png" alt=""/></div>
                <div className="bancos-col "><img className="head-banco" src="img/bancos/interbank.png" alt=""/></div>
                <div className="bancos-col "><img className="head-banco" src="img/bancos/scotianTable.png" alt=""/></div>
              </div>
              <div className="bancos-row">
                <div className="bancos-col">
                  <img src="img/bancos/por-internet.png" alt="" />
                  <p>Por Internet</p>
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/check.png" alt="" />
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/check.png" alt="" />
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/check.png" alt="" />
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/guion.png" alt="" />
                </div>
              </div>
              <div className="bancos-row">
                <div className="bancos-col">
                  <img src="img/bancos/agencias.png" alt="" />
                  <p>Agencias</p>
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/check.png" alt="" />
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/check.png" alt="" />
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/check.png" alt="" />
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/guion.png" alt="" />
                </div>
              </div>
              <div className="bancos-row">
                <div className="bancos-col">
                  <img src="img/bancos/agentes.png" alt="" />
                  <p>Agentes en Lima</p>
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/check.png" alt="" />
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/check.png" alt="" />
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/check.png" alt="" />
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/guion.png" alt="" />
                </div>
              </div>
              <div className="bancos-row">
                <div className="bancos-col">
                  <img src="img/bancos/cuenta-corriente.png" alt="" />
                  <p>Cuenta Corriente (CC)</p>
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/check.png" alt="" />
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/check.png" alt="" />
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/check.png" alt="" />
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/guion.png" alt="" />
                </div>
              </div>
              <div className="bancos-row">
                <div className="bancos-col">
                  <img src="img/bancos/cuenta-interbancaria.png" alt="" />
                  <p>Cuenta Corriente Interbancaria (CCI)</p>
                </div>
                <div className="bancos-col">
                <img src="img/bancos/check.png" alt="" />
                </div>
                <div className="bancos-col">
                <img src="img/bancos/check.png" alt="" />
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/check.png" alt="" />
                </div>
                <div className="bancos-col">
                  <img src="img/bancos/guion.png" alt="" />
                </div>
              </div>
            </article>
          </div>
        </section>
    )
}