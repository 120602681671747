import { useState } from "react"

export function Respuesta ({i, title, children}) {
  const [active, setActive] = useState(false)
  
  const handleClick = () => {
    setActive(!active)
  }

  return (
    <details className="masDetalle" onClick={handleClick}>
      <summary>
        <p><span>{i}.</span>{title}</p>
        <i className={`fas ${active ? 'fa-minus' : 'fa-plus'}`}></i>
      </summary>
      <div>
        {children}
      </div>
    </details>
  )
}