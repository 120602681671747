import { SliderComoFunciona } from "../components/SliderComoFunciona/SliderComoFunciona"
import { SectionPasos } from "../components/SectionPasos/SectionPasos"
import { SectionDetails } from "../components/SectionDetails/SectionDetails"
import { SectionDescubre } from "../components/SectionDescubre/SectionDescubre"
import React from "react";
class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);

  }

  render() {
    return null;
  }
}
export function ComoFunciona () {
  return <>
  <ScrollToTopOnMount />
    <SliderComoFunciona />
    <SectionDescubre />
    <SectionPasos imgPaso1={'img/pasos/img-tab-1.png'} imgPaso2={'img/pasos/img-tab-2.png'} imgPaso3={'img/pasos/img-tab-3.png'} />
    <SectionDetails />
  </>
}