import { useState } from "react"
import { Link, useLocation } from "wouter"
import { useEffect } from "react"

export function Header () {
  const [isActive, setIsActive] = useState(false)
  const [location, ] = useLocation()

  const handleActive = () => {
    setIsActive(!isActive)
  }

  useEffect( () => {
    window.addEventListener('scroll', function()  {
      let elementguia = document.querySelector("body");    
      let elementsitcky = document.querySelector("header"); 
      if(elementguia.getBoundingClientRect().top < 0) { 
          elementsitcky.classList.remove('header-home');     
      } else {     
          elementsitcky.classList.add('header-home');   
      }
    });
  }, [] )

  return ( 
    <header className="header-home">
      <div className="container">
        <div className="row">
          <div className="col-md-2 logo">
            <Link to="/" onClick={() => setIsActive(false)}>
              <a href="" className="custom-logo-link">
                <img height="70" src="img/MIS-CUOTAS_fondo blanco.png" className="custom-logo" alt="" />
              </a>
            </Link>
          </div>
          <div className="col-md-10 menu">
            <nav className="menu-desktop">
              <ul className="nav-h">
              
                <li ><Link className={location === '/comoFunciona' ? 'current-menu-item-desktop': ''} to="/comoFunciona">Cómo funciona</Link></li>
                <li><Link className={location === '/dondeComprar' ? 'current-menu-item-desktop': ''} to="/dondeComprar">Dónde Comprar</Link></li>
                <li><Link className={location === '/pagos' ? 'current-menu-item-desktop': ''} to="/pagos">Pagos</Link></li>
                <li><Link className={location === '/ayuda' ? 'current-menu-item-desktop': ''} to="/ayuda">Ayuda</Link></li>
                <li><Link className={location === '/paraComercios' ? 'current-menu-item-desktop': ''} to="/paraComercios">Para Comercios</Link></li>
                
              </ul>
            </nav>
            <div className="btn-header">
              <a >
                <i className="far fa-flag"></i>
                <span>Perú</span>
              </a>
              <a href="https://msextranet.web.app/auth/acceso" target="_blank">
                <i className="fas fa-coins"></i>
                <span>Pagar</span>
              </a>
              
            </div>
          </div>
          <div className="nav-responsive">
            <div className={`hamburger ${isActive && 'is-active'}`} onClick={handleActive} >
              <div className="_layer -top"></div>
              <div className="_layer -mid"></div>
              <div className="_layer -bottom"></div>
            </div>
            <nav className={`menuppal ${isActive && 'is_active'}`} >
              <ul id="menu-menu-principal-1" className="menu-movil">
                <li className={location === '/comoFunciona' ? 'current-menu-item': ''}>
                
                  <Link to="/comoFunciona" onClick={handleActive}>
                    <img src="img/menu-movil/icon-1.png" alt=""/>
                    <span>Cómo funciona</span>
                  </Link>
                  
                </li>
                <li className={location === '/dondeComprar' ? 'current-menu-item': ''}>
               
                  <Link to="/dondeComprar" onClick={handleActive}>
                    <img src="img/menu-movil/icon-2.png" alt=""/>
                    <span>Dónde Comprar</span>
                  </Link>
                  
                </li>
                <li className={location === '/pagos' ? 'current-menu-item': ''}>
               
                  <Link to="/pagos" onClick={handleActive}>
                    <img src="img/menu-movil/icon-3.png" alt="" />
                    <span>Pagos</span>
                  </Link>
                 
                </li>
                <li className={location === '/ayuda' ? 'current-menu-item': ''}>
                
                  <Link to="/ayuda" onClick={handleActive}>
                    <img src="img/menu-movil/icon-4.png" alt="" />
                    <span>ayuda</span>
                  </Link>
                 
                </li>
                <li className={location === '/paraComercios' ? 'current-menu-item': ''}>
                
                  <Link to="/paraComercios" onClick={handleActive}>
                    <img src="img/menu-movil/icon-5.png" alt="" />
                    <span>Para Comercios</span>
                  </Link>
                  
                </li>
              </ul>
              <div className="footer-menu-movil">
                <div className="social-media">
                  <a  target="_blank"><span className="fab fa-facebook-f"></span></a>
                  <a  target="_blank"><span className="fab fa-linkedin-in"></span></a>
                  <a  target="_blank"><span className="fab fa-twitter"></span></a>
                  <a target="_blank"><span className="fab fa-instagram"></span></a>
                </div>
                <p>
                  Te ayudamos por:<br />
                  <b>WhatsApp:</b> +51 967421523<br />
                  <b>Correo:</b> servicioalcliente@miscuotas.com
                </p>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  )
}