import { Respuesta } from "../Respuesta/Respuesta"

export function RespuestasCuarto () {

  return <>
    <Respuesta i={1} title="¿Tengo que registrarme para comprar con Mis Cuotas?">
      <p>No es necesario registrarse (crear una cuenta) para comprar con Mis Cuotas. Nosotros te registraremos como usuario en nuestra web al momento que accedas al préstamo.</p>
    </Respuesta>
    <Respuesta i={2} title="¿Cómo ingreso a mi cuenta?">
      <p>Para acceder a la cuenta debes ir a www.miscuotas.com e ingresar tu usuario y contraseña. En tu cuenta podrás ver el detalle de tu préstamo, tu cronograma, pagar tus cuotas y mucho más.</p>
    </Respuesta>
  </>
}